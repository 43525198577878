import {
  USER_LOGGED_OUT,
  RESET_PASSWORD,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  CREATE_USER_LOADING,
  CREATE_USER_AUTH_ERROR,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR_DB,
  MODIFY_USER_LOADING,
  MODIFY_USER_EMAIL,
  MODIFY_USER_EMAIL_ERROR,
  MODIFY_USER_PASSWORD,
  MODIFY_USER_PASSWORD_ERROR,
  MODIFY_USER_DB,
  MODIFY_USER_DB_ERROR,
  MODIFY_USER_AUTH_ERROR
} from "../actions/UserActions";

const initialState = {};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case USER_LOGGED_OUT: {
      return state;
    }
    case RESET_PASSWORD: {
      return state;
    }
    case LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        loginError: action.payload.message
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        modifyError: null
      };
    }
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_USER_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case CREATE_USER_AUTH_ERROR: {
      return {
        ...state,
        loading: false,
        signupError: action.payload.message
      }
    }
    case CREATE_USER_ERROR_DB: {
      return {
        ...state,
        loading: false
      }
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }
    case MODIFY_USER_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case MODIFY_USER_EMAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case MODIFY_USER_EMAIL_ERROR: {
      return {
        ...state,
        loading: false,
        modifyError: action.payload.message
      }
    }
    case MODIFY_USER_PASSWORD: {
      return {
        ...state,
        loading: false
      };
    }
    case MODIFY_USER_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        modifyError: action.payload.message
      }
    }
    case MODIFY_USER_DB: {
      return {
        ...state,
        loading: false
      };
    }
    case MODIFY_USER_DB_ERROR: {
      return {
        ...state,
        loading: false,
        modifyError: action.payload.message
      }
    }
    case MODIFY_USER_AUTH_ERROR: {
      return {
        ...state,
        loading: false,
        modifyError: action.payload.message
      }
    }
    default: {
      return state;
    }
  }
};

export default userReducer;

