import React from 'react'
import { Button, CircularProgress } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    wrapper: {
        position: "relative"
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

const LoadingButtonTypeClickComponent = props => {
    let { classes, loading, primaryText, clickAction, clickVariable, name, id, variant, color, className } = props
    return (
        <div className={classes.wrapper}>
            <Button
                name={name}
                id={id}
                variant={variant}
                className={className}
                color={color}
                onClick={() => clickAction(clickVariable)}
                disabled={loading}
            >
                {primaryText}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                />
            )}
        </div>
    )
}

const LoadingButtonTypeSubmitComponent = props => {
    let { classes, loading, primaryText, name, id, variant, color, type, className } = props
    return (
        <div className={classes.wrapper}>
            <Button
                name={name}
                id={id}
                variant={variant}
                className={className}
                color={color}
                type={type}
                disabled={loading}
            >
                {primaryText}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                />
            )}
        </div>
    )
}

const LoadingButtonTypeClick = withStyles(styles, { withTheme: true })(LoadingButtonTypeClickComponent)
const LoadingButtonTypeSubmit = withStyles(styles, { withTheme: true })(LoadingButtonTypeSubmitComponent)

export {
    LoadingButtonTypeClick,
    LoadingButtonTypeSubmit
}