import SignUp from '../prodSessions/SignUp';
import SignIn from "../prodSessions/SignIn";
import NotFound from "../prodSessions/NotFound";
import ForgotPassword from "../prodSessions/ForgotPassword";
import ResetEmailSent from "../prodSessions/ResetEmailSent";
import React from 'react'

const prodSessionRoutes = [
    {
        key: 'signup',
        path: "/session/refer/:id",
        component: SignUp,
        auth: 'logout',
        exact: false
    },
    {
        key: 'signup',
        path: "/session/signup",
        component: SignUp,
        auth: 'logout',
        exact: false
    },
    {
        key: 'signin',
        path: "/session/signin",
        component: SignIn,
        auth: 'logout',
        exact: false
    },
    {
        key: 'forgot-password',
        path: "/session/forgot-password",
        component: ForgotPassword,
        auth: 'logout',
        exact: false
    },
    {
        key: 'reset-email-sent',
        path: "/session/reset-email-sent",
        component: ResetEmailSent,
        auth: 'logout',
        exact: false
    },
    {
        key: 'logout',
        path: "/",
        component: SignIn,
        auth: 'logout',
        exact: false
    }
];

export default prodSessionRoutes;