import {
    CREATE_EVENT_BEGIN,
    CREATE_EVENT,
    CREATE_EVENT_ERROR,
    MODIFY_EVENT_BEGIN,
    MODIFY_EVENT,
    MODIFY_EVENT_ERROR,
    DELETE_EVENT_BEGIN,
    DELETE_EVENT,
    DELETE_EVENT_ERROR
  } from "../actions/EventActions";
  
  const initialState = {};
  
  const eventReducer = function (state = initialState, action) {
    switch (action.type) {
      case CREATE_EVENT_BEGIN:
        return {
          ...state,
          createEventLoading: true
        }
      case CREATE_EVENT:
        return {
          ...state,
          data: action.data,
          createEventLoading: false
        }
      case CREATE_EVENT_ERROR: {
        return {
          ...state,
          error: action.err,
          createEventLoading: false
        };
      }
      case MODIFY_EVENT_BEGIN: {
        return {
          ...state,
          data: action.data,
          modifyEventLoading: true
        };
      }
      case MODIFY_EVENT: {
        return {
          ...state,
          data: action.data,
          modifyEventLoading: false
        };
      }
      case MODIFY_EVENT_ERROR: {
        return {
          ...state,
          error: action.err,
          modifyEventLoading: false
        };
      }
      case DELETE_EVENT_BEGIN: {
        return {
          ...state,
          data: action.data,
          deleteEventLoading: true
        };
      }
      case DELETE_EVENT: {
        return {
          ...state,
          data: action.data,
          deleteEventLoading: false
        };
      }
      case DELETE_EVENT_ERROR: {
        return {
          ...state,
          error: action.err,
          deleteEventLoading: false
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default eventReducer;
  