export const CREATE_SERVICE_BEGIN = "CREATE_SERVICE_BEGIN";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const CREATE_SERVICE_ERROR = "CREATE_SERVICE_ERROR"
export const MODIFY_SERVICE_BEGIN = "MODIFY_SERVICE_BEGIN";
export const MODIFY_SERVICE = "MODIFY_SERVICE";
export const MODIFY_SERVICE_ERROR = "MODIFY_SERVICE_ERROR"
export const EMPLOYEE_LINK_SERVICE_BEGIN = 'EMPLOYEE_LINK_SERVICE_BEGIN'
export const EMPLOYEE_LINK_SERVICE = "EMPLOYEE_LINK_SERVICE";
export const EMPLOYEE_LINK_SERVICE_ERROR = "EMPLOYEE_LINK_SERVICE_ERROR"
export const SET_DISCLAIMER_BEGIN = "SET_DISCLAIMER_BEGIN";
export const SET_DISCLAIMER = "SET_DISCLAIMER";
export const SET_DISCLAIMER_ERROR = "SET_DISCLAIMER_ERROR"

export const createService = (service) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        let FieldValue = getFirestore().FieldValue;

        const uid = getState().firebase.auth.uid
        const businessUid = getState().business.loadedOwnerBusinessUid

        const targetContractUid = service.targetContractUid

        dispatch({
            type: 'CREATE_SERVICE_BEGIN',
            data: null
        })

        firestore.collection("service")
            .add({
                serviceCategory: service.serviceCategory,
                serviceName: service.serviceName,
                cost: service.cost,
                isGST: service.isGST,
                duration: service.duration,
                paddingBefore: service.paddingBefore,
                paddingAfter: service.paddingAfter,
                anyStaff: service.anyStaff,
                serviceActive: service.serviceActive,
                businessUid: businessUid,
                createdByUid: uid,
                createdAt: new Date(),
                lastUpdated: new Date()
            })
            .then((resp) => {
                const serviceId = resp.id
                targetContractUid.forEach(contractId => {
                    firestore.runTransaction(t => {
                        const docRef = firestore.collection("contracts").doc(contractId)
                        return t.get(docRef)
                            .then(doc => {
                                t.update(docRef,
                                    {
                                        'linkedServiceUid': FieldValue.arrayUnion(serviceId)
                                    })
                            })
                    })
                })
            })
            .then(result => {
                dispatch({
                    type: 'CREATE_SERVICE',
                    data: result
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'CREATE_SERVICE_ERROR',
                    err: err
                })
            })
    }
}

export const modifyService = (service, contractsToAdd, contractsToRemove) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        let FieldValue = getFirestore().FieldValue;

        const uid = getState().firebase.auth.uid
        const businessUid = getState().business.loadedOwnerBusinessUid

        const serviceId = service.serviceId

        dispatch({
            type: 'MODIFY_SERVICE_BEGIN',
            data: null
        })

        firestore.runTransaction(t => {
            const docRef = firestore.collection("service").doc(serviceId)
            return t.get(docRef)
                .then(doc => {
                    t.update(docRef, {
                        serviceCategory: service.serviceCategory,
                        serviceName: service.serviceName,
                        cost: service.cost,
                        isGST: service.isGST,
                        duration: service.duration,
                        paddingBefore: service.paddingBefore,
                        paddingAfter: service.paddingAfter,
                        anyStaff: service.anyStaff,
                        serviceActive: service.serviceActive,
                        businessUid: businessUid,
                        createdByUid: uid,
                        //createdAt: new Date(),
                        lastUpdated: new Date()
                    })
                })
        }).then(result => {
            contractsToAdd.forEach(contractId => {
                firestore.runTransaction(t => {
                    const docRef = firestore.collection("contracts").doc(contractId)
                    return t.get(docRef)
                        .then(doc => {
                            if (doc.data().linkedServiceUid.includes(serviceId)) return
                            t.update(docRef,
                                {
                                    'linkedServiceUid': FieldValue.arrayUnion(serviceId)
                                })
                        })
                })
            })
        }).then(result => {
            contractsToRemove.forEach(contractId => {
                firestore.runTransaction(t => {
                    const docRef = firestore.collection("contracts").doc(contractId)
                    return t.get(docRef)
                        .then(doc => {
                            if (!doc.data().linkedServiceUid.includes(serviceId)) return
                            t.update(docRef,
                                {
                                    'linkedServiceUid': FieldValue.arrayRemove(serviceId)
                                })
                        })
                })
            })
        })
            .then((result) => {
                dispatch({
                    type: 'MODIFY_SERVICE',
                    data: result
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'MODIFY_SERVICE_ERROR',
                    err: err
                })
            })
    }
}

export const setDisclaimer = (text) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        let FieldValue = getFirestore().FieldValue;

        const uid = getState().firebase.auth.uid
        const businessUid = getState().business.loadedOwnerBusinessUid

        dispatch({
            type: 'SET_DISCLAIMER_BEGIN',
            data: null
        })

        firestore.collection("disclaimer").doc(businessUid)
            .set({
                text: text,
                businessUid: businessUid,
                ownerUid: uid,
                createdByUid: uid,
                //createdAt: new Date(),
                lastUpdated: new Date()
            })
            .then(result => {
                dispatch({
                    type: 'SET_DISCLAIMER',
                    data: result
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'SET_DISCLAIMER_ERROR',
                    err: err
                })
            })
    }
}

export const employeeLinkService = (newLinkedServiceUid, contract) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        let FieldValue = getFirestore().FieldValue;
        const firestore = getFirestore()

        dispatch({
            type: 'EMPLOYEE_LINK_SERVICE_BEGIN',
            data: null
        })

        firestore.runTransaction(t => {
            const docRef = firestore.collection("contracts").doc(contract.id);
            return t.get(docRef)
                .then(doc => {
                    t.update(docRef, {
                        'linkedServiceUid': newLinkedServiceUid
                    })
                })
        })
            .then((result) => {
                dispatch({
                    type: 'EMPLOYEE_LINK_SERVICE',
                    data: result
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'EMPLOYEE_LINK_SERVICE_ERROR',
                    err: err
                })
            })
    }
}