import React, { Component } from "react";
import { Card, Grid, Button } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { resetPassword } from '../../redux/actions/UserActions'

const ForgotPassword = props => {
    return (
        <div className="signup flex justify-center w-full h-full-screen">
            <div className="p-8">
                <Card className="signup-card position-relative y-center">
                    <div className="p-9 h-full bg-white position-relative">
                        <h5>RESET PASSWORD</h5>
                        <div className="py-1" />
                        <p>A password reset email has been sent to your primary email address. Please follow the steps to reset your password.</p>
                        <div className="py-2" />
                        <Button
                            color="primary"
                            className="capitalize"
                            variant="outlined"
                            onClick={() =>
                                props.history.push("/session/signin")
                            }
                        >
                            Return to sign-in
                        </Button>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default withRouter(ForgotPassword);
