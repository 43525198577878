import React from 'react'

const prodSelectBusinessRoutes = [
    {
        key: 'selectbusiness',
        path: '/selectbusiness',
        component: React.lazy(() => import('../SelectBusiness/SelectBusinessLayout')),
        auth: 'nbl_any',
        exact: false
    },
    {
        key: 'defaultselectbusiness',
        path: '/',
        component: React.lazy(() => import('../SelectBusiness/SelectBusinessLayout')),
        auth: 'nbl_any',
        exact: false
    }
];

export default prodSelectBusinessRoutes;