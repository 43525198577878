import React from 'react'

const prodInviteRoutes = [
    {
        key: 'invitationmanagement',
        path: '/invitationmanagement',
        component: React.lazy(() => import('../Invitations/InviteManagement')),
        auth: 'owner',
        exact: false,
        breadcrumbNameOwner: 'My Team / Invite team',
        breadcrumbNameEmployee: 'Invite'
    }
];

export default prodInviteRoutes;