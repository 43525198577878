import React from 'react'

const prodCalendarRoutes = [
    {
        key: 'mycalendar',
        path: '/mycalendar',
        component: React.lazy(() => import('../Calendar/CalendarLayout')),
        auth: 'any',
        exact: false,
        breadcrumbNameOwner: 'Calendar',
        breadcrumbNameEmployee: 'Calendar'
    }
];

export default prodCalendarRoutes;