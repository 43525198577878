import React, { Component } from "react";
import { Card, Grid, Button } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { resetPassword } from '../../redux/actions/UserActions'

class ForgotPassword extends Component {
  state = {
    email: ''
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    this.props.resetPassword(this.state.email);
  };
  render() {
    let { email } = this.state;

    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <div className="p-9 h-full bg-white position-relative">
            <h5>RESET PASSWORD</h5>
            <div className="py-2" />
              <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                <TextValidator
                  className="mb-6 w-full"
                  variant="outlined"
                  label="Email"
                  onChange={this.handleChange}
                  type="email"
                  name="email"
                  value={email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid"
                  ]}
                />
                <div className="flex items-center">
                  <Button variant="contained" color="primary" type="submit">
                    Reset Password
                      </Button>
                  <span className="ml-4 mr-2">or</span>
                  <Button
                    className="capitalize"
                    onClick={() =>
                      this.props.history.push("/session/signin")
                    }
                  >
                    Sign in
                      </Button>
                </div>
              </ValidatorForm>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetPassword: userCreds => {
    dispatch(resetPassword(userCreds))
  }
})

export default withRouter(
  connect(null, mapDispatchToProps)(ForgotPassword)
);
