import {
    CREATE_OFFER_BEGIN,
    CREATE_OFFER,
    CREATE_OFFER_ERROR,
    MODIFY_OFFER_BEGIN,
    MODIFY_OFFER,
    MODIFY_OFFER_ERROR,
  } from "../actions/OfferActions";
  
  const initialState = {};
  
  const offerReducer = function (state = initialState, action) {
    switch (action.type) {
      case CREATE_OFFER_BEGIN:
        return {
          ...state,
          createOfferLoading: true
        }
      case CREATE_OFFER:
        return {
          ...state,
          data: action.data,
          createOfferLoading: false
        }
      case CREATE_OFFER_ERROR: {
        return {
          ...state,
          error: action.err,
          createOfferLoading: false
        };
      }
      case MODIFY_OFFER_BEGIN: {
        return {
          ...state,
          data: action.data,
          modifyOfferLoading: true
        };
      }
      case MODIFY_OFFER: {
        return {
          ...state,
          data: action.data,
          modifyOfferLoading: false
        };
      }
      case MODIFY_OFFER_ERROR: {
        return {
          ...state,
          error: action.err,
          modifyOfferLoading: false
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default offerReducer;
  