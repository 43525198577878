import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import CustomAvatar from '../Avatars/CustomAvatar'
import Typography from '@material-ui/core/Typography';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Hidden from '@material-ui/core/Hidden';

import { NavLink } from 'react-router-dom'

// const WhiteTextTypography = withStyles({
//     root: {
//         color: "#FFFFFF",
//         flexGrow: 1
//     }
// })(Typography);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({

    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
    listItemText: {
        color: 'black',
    },
}));

const AvatarMenu = (props) => {

    const classes = useStyles()

    let { loadedBusiness, loadedContract, userType } = props

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const unloadBusiness = () => {
        userType === 'owner' ? props.unloadBusiness() : props.unloadContract()
        handleClose()
    }

    const logout = () => {
        props.logoutUser()
        handleClose()
    }

    const avatarDisplayName = userType === 'owner' ? (loadedBusiness && loadedBusiness.businessDisplayName) : (loadedContract && loadedContract.businessData && loadedContract.businessData.businessDisplayName)

    return (
        <div>
            <Button
                onClick={handleClick}
                size="small"
            >
                <Hidden xsDown>
                    <Typography variant="button">
                        {avatarDisplayName}
                    </Typography>
                </Hidden>
                <CustomAvatar
                    reqAvatar={userType === 'owner' ? 'business_avatar' : 'user_avatar'}
                    profile={props.loadedContract && props.loadedContract.employeeData}
                    business={props.loadedBusiness}

                />
                <KeyboardArrowDownIcon />
            </Button>
            <StyledMenu
                id="avatar-menu-owner"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <NavLink to='/selectbusiness'>
                    <StyledMenuItem onClick={unloadBusiness}>
                        <ListItemIcon>
                            <ShuffleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary="Switch Business"
                            classes={{ primary: classes.listItemText }}
                        />
                    </StyledMenuItem>
                </NavLink>
                {userType === 'owner' ? (
                    <NavLink to='/editbusiness'>
                        <StyledMenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Business Settings"
                                classes={{ primary: classes.listItemText }}
                            />
                        </StyledMenuItem>
                    </NavLink>
                ) : null}
                <NavLink to='/editprofile'>
                    <StyledMenuItem onClick={unloadBusiness}>
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary="My profile"
                            classes={{ primary: classes.listItemText }}
                        />
                    </StyledMenuItem>
                </NavLink>
                <StyledMenuItem
                    onClick={logout}
                >
                    <ListItemIcon onClick={handleClose}>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}

export default AvatarMenu