import React from 'react'
import {
    CircularProgress,
    Grid
} from "@material-ui/core";

const Suspense = () => {
    return (
        <div className="py-20">
        <Grid container justify="center" alignItems="center">
            <CircularProgress
                size={50}
            />
        </Grid>
        </div>
    )
}

export default Suspense