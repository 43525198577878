import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./reducers/RootReducer";
import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore'
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase'
import firebase from 'firebase/app'
import "firebase/storage";
import firebaseConfig from '../services/firebase/firebaseConfig'

const middlewares = [
  thunk.withExtraArgument({ getFirestore, getFirebase })
  // This is where you add other middleware like redux-observable
];

// let devtools = x => x;

// if (
//   process.env.NODE_ENV !== "production" &&
//   process.browser &&
//   window.__REDUX_DEVTOOLS_EXTENSION__
// ) {
//   devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
// }

export const Store = createStore(
  RootReducer,
  //initialState,
  compose(applyMiddleware(...middlewares),
    reduxFirestore(firebase, firebaseConfig),
    //devtools
  )
);

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB 
} // react-redux-firebase config

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: Store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}