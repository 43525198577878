import { stripeTestClientID, stripeProdClientId } from '../../../secrets/secretStripe'

const crypto = require('crypto');

export const ONBOARDING_PREWORK_LOADING = 'ONBOARDING_PREWORK_LOADING';
export const ONBOARDING_PREWORK_SUCCESS = 'ONBOARDING_PREWORK_SUCCESS';
export const ONBOARDING_PREWORK_FAILURE = 'ONBOARDING_PREWORK_FAILURE';

export const startStripeOnboarding = (token) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()

        dispatch({
            type: 'ONBOARDING_PREWORK_LOADING'
        })

        const uid = getState().firebase.auth.uid
        const profile = getState().firebase.profile
        const businessUid = getState().business.loadedOwnerBusinessUid

        const docRefStripeBusAcc = firestore.collection("stripeBusinessAccount").doc();

        const stateTokenId = docRefStripeBusAcc.id //businessUid + '_' + crypto.randomBytes(64).toString('hex');

        firestore.runTransaction(t => {
            return t.get(docRefStripeBusAcc)
                .then(doc => {
                    t.set(docRefStripeBusAcc, {
                        businessUid,
                        createdByUid: uid,
                        confirmation_complete: false,
                        createdAt: new Date(),
                        lastUpdated: new Date()
                    }, { merge: true });
                })
                .then(resp => {
                    //const stripeUrl = `https://connect.stripe.com/express/oauth/authorize?client_id=${stripeProdClientId}&state=${stateTokenId}&suggested_capabilities[]=transfers&stripe_user[email]=${profile.email}`
                    const stripeUrl = `https://connect.stripe.com/express/oauth/authorize?client_id=${stripeProdClientId}&state=${stateTokenId}&stripe_user[email]=${profile.email}`
                    dispatch({
                        type: 'ONBOARDING_PREWORK_SUCCESS'
                    })
                    window.open(stripeUrl, 'stripeSetup', "height=700,width=500,menubar=0,status=0,left=100,top=100");
                })
                .catch(resp => {
                    dispatch({
                        type: 'ONBOARDING_PREWORK_FAILURE'
                    })
                })
        })


    }
}

