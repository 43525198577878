import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import businessReducer from "./BusinessReducer"
import inviteReducer from "./InviteReducer"
import contractReducer from "./ContractReducer"
import serviceReducer from "./ServiceReducer"
import offerReducer from "./OfferReducer"
import eventReducer from './EventReducer'
import snapshotReducer from './SnapshotReducer'
import storageReducer from './StorageReducer'
import stripeReducer from './StripeReducer'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore' // <- needed if using firestore
import { USER_LOGGED_OUT } from "../actions/UserActions";

const AppReducer = combineReducers({
  user: UserReducer,

  business: businessReducer,
  invite: inviteReducer,
  contract: contractReducer,
  service: serviceReducer,
  offer: offerReducer,
  event: eventReducer,
  storage: storageReducer,
  stripe: stripeReducer,

  snapshot: snapshotReducer,

  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

const RootReducer = (state, action) => {
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined
  }

  return AppReducer(state, action)
}

export default RootReducer;
