import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyDAniKQc30F4jvT-w7BChce--vQra_Ktco",
  authDomain: "appt-business.firebaseapp.com",
  databaseURL: "https://appt-business.firebaseio.com",
  projectId: "appt-business",
  storageBucket: "appt-business.appspot.com",
  messagingSenderId: "613221706809",
  appId: "1:613221706809:web:d25ffe0c513c676d3eb1e8",
  measurementId: "G-19WLPB5161",
};

export default firebaseConfig;
