import { sendEmail } from '../../services/emailjs/SendEmail'

export const INVITE_FROM_EMPLOYEE_LOADING = 'INVITE_FROM_EMPLOYEE_LOADING'
export const INVITE_FROM_EMPLOYEE_CREATE = "INVITE_FROM_EMPLOYEE_CREATE";
export const INVITE_FROM_EMPLOYEE_CREATE_ERROR = "INVITE_FROM_EMPLOYEE_CREATE_ERROR";
export const INVITE_APPROVE = "INVITE_FROM_EMPLOYEE_APPROVE";
export const INVITE_APPROVE_ERROR = "INVITE_FROM_EMPLOYEE_APPROVE_ERROR";
export const INVITE_DENY = "INVITE_FROM_EMPLOYEE_DENY";
export const INVITE_DENY_ERROR = "INVITE_FROM_EMPLOYEE_DENY_ERROR";
export const INVITE_FROM_BUSINESS_LOADING = 'INVITE_FROM_BUSINESS_LOADING'
export const INVITE_FROM_BUSINESS_CREATE = 'INVITE_FROM_BUSINESS_CREATE'
export const INVITE_FROM_BUSINESS_CREATE_ERROR = 'INVITE_FROM_BUSINESS_CREATE_ERROR'
export const DELETE_INVITE_LOADING = 'DELETE_INVITE_LOADING'
export const DELETE_INVITE = 'DELETE_INVITE'
export const DELETE_INVITE_ERROR = 'DELETE_INVITE_ERROR'
export const GET_REFERRAL_LOADING = 'GET_REFERRAL_LOADING'
export const GET_REFERRAL = 'GET_REFERRAL'
export const GET_REFERRAL_NODOC = 'GET_REFERRAL_NODOC'
export const GET_REFERRAL_ERROR = 'GET_REFERRAL_ERROR'
export const CLEAR_REFERRAL = 'CLEAR_REFERRAL'

export const inviteFromEmployeeCreate = (invite) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        dispatch({
            type: 'INVITE_FROM_EMPLOYEE_LOADING'
        })

        firestore.collection("business").doc(invite.businessUid)
            .get().then(doc => {

                const profile = getState().firebase.profile

                firestore.collection("invitations").add({
                    invitationType: 'fromEmployee',
                    inviteEmail: null,
                    businessUid: invite.businessUid,
                    businessData: doc.data(),
                    employeeUid: invite.uid,
                    employeeData: profile,
                    inviteActive: true,
                    inviteApproved: null,
                    createdByUid: invite.uid,
                    createdAt: new Date(),
                    lastUpdated: new Date()
                })
            })
            .then((resp) => {
                dispatch({
                    type: 'INVITE_FROM_EMPLOYEE_CREATE',
                    data: { resp, invite }
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'INVITE_FROM_EMPLOYEE_CREATE_ERROR',
                    err: err
                })
            })

    }
}

export const approveInvitation = (invite) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        const uid = getState().firebase.auth.uid
        firestore.collection("invitations").doc(invite.id).update({
            "inviteActive": false,
            "inviteApproved": true
        })
        firestore.collection("contracts").add({
            businessUid: invite.businessUid,
            businessData: invite.businessData,
            employeeUid: invite.employeeUid,
            employeeData: invite.employeeData,
            linkedServiceUid: [],
            workingHours: [
                {
                    day: 'Monday',
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                    duration: null
                },
                {
                    day: 'Tuesday',
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                    duration: null
                },
                {
                    day: 'Wednesday',
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                    duration: null
                },
                {
                    day: 'Thursday',
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                    duration: null
                },
                {
                    day: 'Friday',
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                    duration: null
                },
                {
                    day: 'Saturday',
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                    duration: null
                },
                {
                    day: 'Sunday',
                    startHour: null,
                    startMinute: null,
                    endHour: null,
                    endMinute: null,
                    duration: null
                }
            ],
            contractActive: true,
            createdFromInvitation: invite.id,
            createdByUid: uid,
            onboardingComplete: false,
            createdAt: new Date(),
            lastUpdated: new Date()
        }).then((resp) => {
            dispatch({
                type: 'INVITE_APPROVE',
                data: { resp, invite }
            })
        }).catch((err) => {
            dispatch({
                type: 'INVITE_APPROVE_ERROR',
                err: err
            })
        })
    }
}

export const denyInvitation = (invite) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        firestore.collection("invitations").doc(invite.id).update({
            "inviteActive": false,
            "inviteApproved": false
        }).then((resp) => {
            dispatch({
                type: 'INVITE_DENY',
                data: { resp, invite }
            })
        }).catch((err) => {
            dispatch({
                type: 'INVITE_DENY_ERROR',
                err: err
            })
        })
    }
}

export const inviteFromBusinessCreate = (email) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        const businessUid = getState().business.loadedOwnerBusinessUid
        const profile = getState().firebase.profile
        const uid = getState().firebase.auth.uid

        dispatch({
            type: 'INVITE_FROM_BUSINESS_LOADING'
        })

        let usersRef = firestore.collection('users');

        usersRef.where('email', '==', email)
            .get()
            .then(snapshot => {
                if (snapshot.empty) {
                    firestore.collection("business").doc(businessUid)
                        .get().then(doc => {
                            const businessName = doc.data().businessDisplayName
                            firestore.collection("invitations").add({
                                invitationType: 'fromBusiness_withoutUser',
                                inviteEmail: email,
                                businessUid: businessUid,
                                businessData: doc.data(),
                                employeeUid: null,
                                employeeData: null,
                                inviteActive: true,
                                inviteApproved: null,
                                createdByUid: uid,
                                createdAt: new Date(),
                                lastUpdated: new Date()
                            }).then(doc => {
                                sendEmail('invitefrombusinesscreate_withoutuser', {
                                    inviteId: doc.id,
                                    recipFirstName: null,
                                    recipEmail: email,
                                    businessName: businessName,
                                    invitedBy: profile.displayNameFull
                                })
                            })

                        })
                }
                if (!snapshot.empty) {
                    snapshot.forEach(doc => {
                        const userDoc = doc.data()
                        const userId = doc.id
                        firestore.collection("business").doc(businessUid)
                            .get().then(doc => {
                                const businessName = doc.data().businessDisplayName
                                firestore.collection("invitations").add({
                                    invitationType: 'fromBusiness_withUser',
                                    inviteEmail: email,
                                    businessUid: businessUid,
                                    businessData: doc.data(),
                                    employeeUid: userId,
                                    employeeData: userDoc,
                                    inviteActive: true,
                                    inviteApproved: null,
                                    createdByUid: uid,
                                    createdAt: new Date(),
                                    lastUpdated: new Date()
                                }).then(doc => {
                                    sendEmail('invitefrombusinesscreate_withuser', {
                                        inviteId: doc.id,
                                        recipFirstName: userDoc.firstName,
                                        recipEmail: email,
                                        businessName: businessName,
                                        invitedBy: profile.displayNameFull
                                    })
                                })
                            })
                    })
                }
            })


            .then((resp) => {

                dispatch({
                    type: 'INVITE_FROM_BUSINESS_CREATE',
                    data: { resp, email }
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'INVITE_FROM_BUSINESS_CREATE_ERROR',
                    err: err
                })
            })

    }
}

export const deleteInvite = (invite) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        dispatch({
            type: 'DELETE_INVITE_LOADING'
        })

        firestore.runTransaction(t => {
            const docRef = firestore.collection("invitations").doc(invite.id)
            return t.get(docRef)
                .then(doc => {
                    t.delete(docRef)
                })
                .then((result) => {
                    dispatch({
                        type: 'DELETE_INVITE',
                        data: result
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: 'DELETE_INVITE_ERROR',
                        err: err
                    })
                })
        })
    }
}

export const getReferralInvitation = (referralId) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        dispatch({
            type: 'GET_REFERRAL_LOADING'
        })

        firestore.collection("invitations").doc(referralId).get()
            .then(doc => {
                if (doc.exists && doc.data().inviteActive === true) {
                    dispatch({
                        type: 'GET_REFERRAL',
                        data: doc.data()
                    })
                }
                else {
                    dispatch({
                        type: 'GET_REFERRAL_NODOC'
                    })
                }

            })
            .catch((err) => {
                dispatch({
                    type: 'GET_REFERRAL_ERROR',
                    err: err
                })
            })
    }
}

export const clearReferral = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({
            type: 'CLEAR_REFERRAL'
        })
    }
}