import React from 'react'

const prodEditServicesRoutes = [
    {
        key: 'editservices',
        path: '/editservices',
        component: React.lazy(() => import('../EditServices/EditServices')),
        auth: 'owner',
        exact: false,
        breadcrumbNameOwner: 'My Business / Services',
        breadcrumbNameEmployee: 'Services'
    },
    {
        key: 'employeeservices',
        path: '/employeeservices',
        component: React.lazy(() => import('../EditServices/EmployeeManageServices')),
        auth: 'employee',
        exact: false,
        breadcrumbNameOwner: 'My Business / Services',
        breadcrumbNameEmployee: 'Services'
    }
];

export default prodEditServicesRoutes;