import React from 'react'

const prodPaymentsRoutes = [
    {
        key: 'payments',
        path: '/payments',
        component: React.lazy(() => import('../Payments/Payments')),
        auth: 'owner',
        exact: false,
        breadcrumbNameOwner: 'My Business / Payments',
        breadcrumbNameEmployee: 'Payments'
    },
];

export default prodPaymentsRoutes;