import {
  INVITE_FROM_EMPLOYEE_LOADING,
  INVITE_FROM_EMPLOYEE_CREATE,
  INVITE_FROM_EMPLOYEE_CREATE_ERROR,
  INVITE_APPROVE,
  INVITE_APPROVE_ERROR,
  INVITE_DENY,
  INVITE_DENY_ERROR,
  INVITE_FROM_BUSINESS_LOADING,
  INVITE_FROM_BUSINESS_CREATE,
  INVITE_FROM_BUSINESS_CREATE_ERROR,
  DELETE_INVITE_LOADING,
  DELETE_INVITE,
  DELETE_INVITE_ERROR,
  GET_REFERRAL_LOADING,
  GET_REFERRAL,
  GET_REFERRAL_NODOC,
  GET_REFERRAL_ERROR,
  CLEAR_REFERRAL
} from "../actions/InviteActions";

const initialState = {};

const inviteReducer = function (state = initialState, action) {
  switch (action.type) {
    case INVITE_FROM_EMPLOYEE_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case INVITE_FROM_EMPLOYEE_CREATE: {
      return {
        ...state,
        data: action.data,
        loading: false
      }
    }
    case INVITE_FROM_EMPLOYEE_CREATE_ERROR: {
      return {
        ...state,
        error: action.err,
        loading: false
      };
    }
    case INVITE_APPROVE: {
      return {
        ...state,
        data: action.data
      }
    }
    case INVITE_APPROVE_ERROR: {
      return {
        ...state,
        error: action.err
      };
    }
    case INVITE_DENY: {
      return {
        ...state,
        data: action.data
      }
    }
    case INVITE_DENY_ERROR: {
      return {
        ...state,
        error: action.err
      };
    }
    case INVITE_FROM_BUSINESS_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case INVITE_FROM_BUSINESS_CREATE: {
      return {
        ...state,
        data: action.data,
        loading: false
      }
    }
    case INVITE_FROM_BUSINESS_CREATE_ERROR: {
      return {
        ...state,
        error: action.err,
        loading: false
      }
    }
    case DELETE_INVITE_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case DELETE_INVITE: {
      return {
        ...state,
        data: action.data,
        loading: false
      }
    }
    case DELETE_INVITE_ERROR: {
      return {
        ...state,
        error: action.err,
        loading: false
      }
    }

    case GET_REFERRAL_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_REFERRAL: {
      return {
        ...state,
        referralData: action.data,
        loading: false
      }
    }
    case GET_REFERRAL_NODOC: {
      return {
        ...state,
        loading: false
      }
    }
    case GET_REFERRAL_ERROR: {
      return {
        ...state,
        error: action.err,
        loading: false
      }
    }
    case CLEAR_REFERRAL: {
      return {
        ...state,
        referralData: null
      }
    }
    default: {
      return state;
    }
  }
};

export default inviteReducer;