import emailjs from 'emailjs-com';

export const sendEmail = (templateId, templateParams) => {

   console.log('email send process')

    emailjs.send('buksa.app@gmail.com', templateId, templateParams, 'user_iomiPBGdQrEjEQHFCPzft')
        .then(response => {
           console.log('SUCCESS!', response.status, response.text);
        }, error => {
           console.log('FAILED...', error);
        });
}