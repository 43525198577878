import {
    ONBOARDING_PREWORK_LOADING,
    ONBOARDING_PREWORK_SUCCESS,
    ONBOARDING_PREWORK_FAILURE
} from "../actions/StripeActions";

const initialState = {};

const stripeReducer = function (state = initialState, action) {
    switch (action.type) {
        case ONBOARDING_PREWORK_LOADING: {
            return {
                ...state,
                loadingOnboarding: true
            }
        }
        case ONBOARDING_PREWORK_SUCCESS: {
            return {
                ...state,
                loadingOnboarding: false
            }
        }
        case ONBOARDING_PREWORK_FAILURE: {
            return {
                ...state,
                loadingOnboarding: false
            };
        }
        default: {
            return state;
        }
    }
};

export default stripeReducer;
