import history from "history.js";

export const CREATE_BUSINESS = "CREATE_BUSINESS";
export const CREATE_BUSINESS_ERROR = "CREATE_BUSINESS_ERROR";

export const MODIFY_BUSINESS = "CREATE_BUSINESS";
export const MODIFY_BUSINESS_ERROR = "CREATE_BUSINESS_ERROR";

export const LOAD_OWNER_BUSINESS = 'LOAD_OWNER_BUSINESS';
export const UNLOAD_OWNER_BUSINESS = 'UNLOAD_OWNER_BUSINESS';

export const createBusiness = (business) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        const streetAddressPublic = business.streetAddressVisibility ? business.streetAddress : null
        const streetAddressPrivate = business.streetAddress

        const uid = getState().firebase.auth.uid
        const profile = getState().firebase.profile

        var docRefBusiness = firestore.collection("business").doc();
        var docRefBusinessPrivate = firestore.collection("businessPrivateInfo").doc(docRefBusiness.id)
        var docRefContract = firestore.collection("contracts").doc()

        const createdAt = new Date()
        const lastUpdated = new Date()

        firestore.runTransaction(transaction => {
            //console.log(docRefBusiness)

            transaction.set(docRefBusiness,
                {
                    businessType: business.businessType,
                    businessIndustry: business.businessIndustry,
                    businessDisplayName: business.businessDisplayName,
                    contactPhoneNumber: business.contactPhoneNumber,
                    streetAddressPublic: streetAddressPublic,
                    suburb: business.suburb,
                    state: business.state,
                    postcode: business.postcode,
                    maxRadius: business.maxRadius,
                    streetAddressVisibility: business.streetAddressVisibility,
                    ownerUid: business.createdByUid,
                    businessActive: true,
                    createdByUid: business.createdByUid,
                    onboardingComplete: false,
                    createdAt: createdAt,
                    lastUpdated: lastUpdated
                }
            )
            transaction.set(docRefBusinessPrivate,
                {
                    businessLegalName: business.businessLegalName,
                    ownerLegalName: business.ownerLegalName,
                    streetAddressPrivate: streetAddressPrivate,
                    suburb: business.suburb,
                    state: business.state,
                    postcode: business.postcode,
                }
            )
            transaction.set(docRefContract,
                {
                    businessUid: docRefBusiness.id,
                    businessData: {
                        businessType: business.businessType,
                        businessIndustry: business.businessIndustry,
                        businessDisplayName: business.businessDisplayName,
                        contactPhoneNumber: business.contactPhoneNumber,
                        streetAddressPublic: streetAddressPublic,
                        suburb: business.suburb,
                        state: business.state,
                        postcode: business.postcode,
                        streetAddressVisibility: business.streetAddressVisibility,
                        ownerUid: business.createdByUid,
                        businessActive: true,
                        createdByUid: business.createdByUid,
                        onboardingComplete: false,
                        createdAt: createdAt,
                        lastUpdated: lastUpdated
                    },
                    employeeUid: uid,
                    employeeData: profile,
                    linkedServiceUid: [],
                    workingHours: [
                        {
                            day: 'Monday',
                            startHour: null,
                            startMinute: null,
                            endHour: null,
                            endMinute: null,
                            duration: null
                        },
                        {
                            day: 'Tuesday',
                            startHour: null,
                            startMinute: null,
                            endHour: null,
                            endMinute: null,
                            duration: null
                        },
                        {
                            day: 'Wednesday',
                            startHour: null,
                            startMinute: null,
                            endHour: null,
                            endMinute: null,
                            duration: null
                        },
                        {
                            day: 'Thursday',
                            startHour: null,
                            startMinute: null,
                            endHour: null,
                            endMinute: null,
                            duration: null
                        },
                        {
                            day: 'Friday',
                            startHour: null,
                            startMinute: null,
                            endHour: null,
                            endMinute: null,
                            duration: null
                        },
                        {
                            day: 'Saturday',
                            startHour: null,
                            startMinute: null,
                            endHour: null,
                            endMinute: null,
                            duration: null
                        },
                        {
                            day: 'Sunday',
                            startHour: null,
                            startMinute: null,
                            endHour: null,
                            endMinute: null,
                            duration: null
                        }
                    ],
                    contractActive: true,
                    createdFromInvitation: null,
                    createdByUid: uid,
                    createdAt: createdAt,
                    lastUpdated: lastUpdated
                }
            )
            return Promise.resolve()
        }).then((resp) => {
            history.push({ pathname: '/' })
            dispatch({
                type: 'CREATE_BUSINESS',
                data: { resp, business }
            })
        })
            .catch((err) => {
                dispatch({
                    type: 'CREATE_BUSINESS_ERROR',
                    err: err
                })
            })
    }
}

export const modifyBusiness = (business) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        const streetAddressPublic = business.streetAddressVisibility ? business.streetAddress : null
        const streetAddressPrivate = business.streetAddress

        const uid = getState().firebase.auth.uid
        const profile = getState().firebase.profile

        var docRefBusiness = firestore.collection("business").doc(business.businessUid);
        var docRefBusinessPrivate = firestore.collection("businessPrivateInfo").doc(business.businessUid)

        const lastUpdated = new Date()

        firestore.runTransaction(transaction => {
            //console.log(docRefBusiness)

            transaction.update(docRefBusiness,
                {
                    businessType: business.businessType,
                    businessIndustry: business.businessIndustry,
                    businessDisplayName: business.businessDisplayName,
                    contactPhoneNumber: business.contactPhoneNumber,
                    streetAddressPublic: streetAddressPublic,
                    suburb: business.suburb,
                    state: business.state,
                    postcode: business.postcode,
                    maxRadius: business.maxRadius,
                    streetAddressVisibility: business.streetAddressVisibility,
                    ownerUid: business.createdByUid,
                    businessActive: true,
                    createdByUid: business.createdByUid,
                    lastUpdated: lastUpdated
                }
            )
            transaction.update(docRefBusinessPrivate,
                {
                    businessLegalName: business.businessLegalName,
                    ownerLegalName: business.ownerLegalName,
                    streetAddressPrivate: streetAddressPrivate,
                    suburb: business.suburb,
                    state: business.state,
                    postcode: business.postcode,
                }
            )
            return Promise.resolve()
        }).then((resp) => {
            history.push({ pathname: '/' })
            dispatch({
                type: 'MODIFY_BUSINESS',
                data: { resp, business }
            })
        })
            .catch((err) => {
                dispatch({
                    type: 'MODIFY_BUSINESS_ERROR',
                    err: err
                })
            })
    }
}


export const loadOwnerBusiness = (business, redirectLocation) => {
    return (dispatch) => {

        history.push({ pathname: redirectLocation })
        dispatch({
            type: 'LOAD_OWNER_BUSINESS',
            loadedOwnerBusiness: business
        })

    }
}

export const unloadOwnerBusiness = () => {
    return (dispatch) => {
        //console.log(business)
        dispatch({
            type: 'UNLOAD_OWNER_BUSINESS'
        })
    }
}
