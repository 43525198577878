import React from 'react'

const prodDashboardRoutes = [
    {
        key: 'dashboard',
        path: '/',
        component: React.lazy(() => import('../Dashboard/Dashboard')),
        auth: 'any',
        exact: true,
        breadcrumbNameOwner: 'Dashboard',
        breadcrumbNameEmployee: 'Dashboard'
    }
];

export default prodDashboardRoutes;