const addToArray = (value, array) => {
    const tempArray = array.slice()
    if (tempArray.includes(value)) return tempArray
    if (!tempArray.includes(value)) return [...tempArray, value]
}

const removeFromArray = (value, array) => {
    const tempArray = array.slice()
    if (!tempArray.includes(value)) return tempArray
    if (tempArray.includes(value)) return tempArray.filter(arrayVal => arrayVal != value)
}

const initialState = {
    isLoading: []
};

const snapshotReducer = function (state = initialState, action) {
    switch (action.type) {
        case '@@reduxFirestore/SET_LISTENER': {
            const isLoading = addToArray(action.meta.storeAs, state.isLoading)
            return {
                ...state,
                isLoading
            }
        }
        case '@@reduxFirestore/LISTENER_RESPONSE': {
            const isLoading = removeFromArray(action.meta.storeAs, state.isLoading)
            return {
                ...state,
                isLoading
            }
        }
        case '@@reduxFirestore/UNSET_LISTENER': {
            const isLoading = removeFromArray(action.meta.storeAs, state.isLoading)
            return {
                ...state,
                isLoading
            }
        }
        default: {
            return state;
        }
    }
};

export default snapshotReducer;