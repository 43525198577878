import React, { Fragment, useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, green, purple } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import clsx from 'clsx';
import CreateIcon from '@material-ui/icons/Create';
import { setImage, getImage } from '../../redux/actions/StorageActions'
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        //display: 'flex',
        position: 'relative',
        '& > *': {
            marginLeft: theme.spacing(1)
        },
    },
    circleDefault: {
        color: theme.palette.getContrastText(purple[900]),
        backgroundColor: purple[900],
    },
    circleBig: {
        height: 100,
        width: 100,
        fontSize: 50
    },
    squareDefault: {
        color: theme.palette.getContrastText(purple[900]),
        backgroundColor: purple[900],
    },
    squareBig: {
        height: 100,
        width: 200,
        fontSize: 50
    },
    editIconProfile: {
        position: "absolute",
        top: "55%",
        left: "65%",
        color: 'black',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'gray'
        }
    },
    editIconBusiness: {
        position: "absolute",
        top: "60%",
        left: "88%",
        color: 'black',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'gray'
        }
    },
    input: {
        display: 'none',
    }
}));

const CustomAvatar = (props) => {

    const classes = useStyles();

    let {
        storage,
        business,
        profile,
        reqAvatar,
        isEditProfile,
        isEditBusiness
    } = props

    const defaultAvatarName = reqAvatar === 'business_avatar' ? (
        business && business.businessDisplayName && business.businessDisplayName.substring(0, 1)
    ) : (
            profile && profile.initials
        )

    const variant = reqAvatar === 'business_avatar' ? 'square' : 'circle'

    const avatarStoragePath = reqAvatar === 'business_avatar' ? (
        business && business.avatarPath
    ) : (
            profile && profile.avatarPath
        )

    //uploading process
    const [imgFile, setImgFile] = useState(null)

    const handleImageAsFile = (e) => {
        const image = e.target.files[0]
        setImgFile(image)
    }

    useEffect(() => {
        props.setImage(imgFile, reqAvatar, avatarStoragePath)
    }, [imgFile]
    )

    // render image
    useEffect(() => {
        props.getImage(avatarStoragePath)
    }, [avatarStoragePath])

    const hasImg = avatarStoragePath ? true : false

    return (
        <div className={classes.root}>
            {hasImg ? (
                <Avatar
                    variant={variant}
                    src={storage && storage[avatarStoragePath]}
                    className={clsx(
                        isEditProfile && classes.circleBig,
                        isEditBusiness && classes.squareBig
                    )}
                />
            ) : (
                    <Avatar
                        variant={variant}
                        className={clsx(
                            variant === 'square' ? classes.squareDefault : classes.circleDefault,
                            isEditProfile && classes.circleBig,
                            isEditBusiness && classes.squareBig
                        )}
                    >
                        {defaultAvatarName}
                    </Avatar>
                )
            }
            {isEditProfile || isEditBusiness ? (
                <Fragment>
                    <input
                        accept="image/*"
                        className={classes.input}
                        id="upload-avatar"
                        multiple
                        type="file"
                        onChange={handleImageAsFile}
                    />
                    <label htmlFor="upload-avatar">
                        <IconButton
                            onClick={props.avatarEditClick}
                            className={clsx(isEditProfile ? classes.editIconProfile : classes.editIconBusiness)}
                            component="span"
                        >
                            <CreateIcon />
                        </IconButton>
                    </label>
                </Fragment>
            ) : null
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        storage: state.storage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setImage: (imgFile, imgType) => {
            dispatch(setImage(imgFile, imgType))
        },
        getImage: (imgPath) => {
            dispatch(getImage(imgPath))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomAvatar)