import React, { useEffect}  from 'react';
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import PaymentIcon from '@material-ui/icons/Payment';

import DashboardIcon from '@material-ui/icons/Dashboard';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import MailIcon from '@material-ui/icons/Mail';
import EventIcon from '@material-ui/icons/Event';


// {
//   name: "Select Business",
//     path: "/selectbusiness",
//       iconText: "A"
// },
// {
//   name: "Create Business",
//     path: "/createbusiness",
//       iconText: "B"
// },
// {
//   name: "Manage my services",
//     path: "/editservices",
//       iconText: "C"
// },
// {
//   name: "Employee services",
//     path: "/employeeservices",
//       iconText: "C"
// },
// {
//   name: "Manage invitations",
//     path: "/invitationmanagement",
//       iconText: "C"
// },
// {
//   name: "Manage availability",
//     path: "/manageavailability",
//       iconText: "C"
// },
// {
//   name: "Manage offers",
//     path: "/offermanagement",
//       iconText: "C"
// },
// {
//   name: "Calendar",
//     path: "/mycalendar",
//       iconText: "C"
// }

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listItemText: {
    color: 'black',
  },
}));

const ProdNavListOwner = props => {

  const classes = useStyles();
  const path = props.history.location.pathname

  const [openA, setOpenA] = React.useState(true);
  const handleClickA = () => {
    setOpenA(!openA);
  };

  const [openB, setOpenB] = React.useState(true);
  const handleClickB = () => {
    setOpenB(!openB);
  };

  const [selectedIndex, setSelectedIndex] = React.useState('/');

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(path)
  })


  return (
    <List
      component="nav"
      className={classes.root}
    >

      <NavLink to='/'>
        <ListItem
          button
          selected={selectedIndex === '/'}
          onClick={(event) => handleListItemClick(event, '/')}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            classes={{ primary: classes.listItemText }}
          />
        </ListItem>
      </NavLink>

      <NavLink to='/mycalendar'>
        <ListItem
          button
          selected={selectedIndex === '/mycalendar'}
          onClick={(event) => handleListItemClick(event, '/mycalendar')}
        >
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText
            primary="Calendar"
            classes={{ primary: classes.listItemText }}
          />
        </ListItem>
      </NavLink>

      <ListItem button onClick={handleClickA}>
        <ListItemIcon>
          <StorefrontIcon />
        </ListItemIcon>
        <ListItemText primary="My Business" />
        {openA ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openA} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to='/editservices'>
            <ListItem
              button
              selected={selectedIndex === '/editservices'}
              onClick={(event) => handleListItemClick(event, '/editservices')}
              className={classes.nested}
            >
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText
                primary="Services"
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
          <NavLink to='/offermanagement'>
            <ListItem
              button
              selected={selectedIndex === '/offermanagement'}
              onClick={(event) => handleListItemClick(event, '/offermanagement')}
              className={classes.nested}
            >
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText
                primary="Offers"
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
          <NavLink to='/payments'>
          <ListItem
            button
            selected={selectedIndex === '/payments'}
            onClick={(event) => handleListItemClick(event, '/payments')}
            className={classes.nested}
          >
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText
              primary="Payments"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </NavLink>
        </List>
      </Collapse>

      <ListItem button onClick={handleClickB}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="My Team" />
        {openB ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openB} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink to='/invitationmanagement'>
            <ListItem
              button
              selected={selectedIndex === '/invitationmanagement'}
              onClick={(event) => handleListItemClick(event, '/invitationmanagement')}
              className={classes.nested}
            >
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText
                primary="Invite team"
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
          <NavLink to='/manageavailability'>
            <ListItem
              button
              selected={selectedIndex === '/manageavailability'}
              onClick={(event) => handleListItemClick(event, '/manageavailability')}
              className={classes.nested}
            >
              <ListItemIcon>
                <AvTimerIcon />
              </ListItemIcon>
              <ListItemText
                primary="Roster"
                classes={{ primary: classes.listItemText }}
              />
            </ListItem>
          </NavLink>
        </List>
      </Collapse>

    </List>
  );
}

export default ProdNavListOwner