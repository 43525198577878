import React from 'react'

const prodEmployeeRosterRoutes = [
    {
        key: 'manageavailability',
        path: '/manageavailability',
        component: React.lazy(() => import('../EmployeeRoster/EmployeeManageHours')),
        auth: 'any',
        exact: false,
        breadcrumbNameOwner: 'My Team / Roster',
        breadcrumbNameEmployee: 'Roster'
    }
];

export default prodEmployeeRosterRoutes;