export const GET_IMAGE_LOADING = "GET_IMAGE_LOADING";
export const GET_IMAGE = "GET_IMAGE";
export const GET_IMAGE_ERROR = "GET_IMAGE_ERROR"

export const setImage = (imgFile, imgType, prevImgPath) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()

        const userType = getState().firebase.profile.userType
        const uid = getState().firebase.auth.uid
        const businessUid = userType === 'owner' ? getState().business.loadedOwnerBusinessUid : getState().contract.loadedEmployeeBusinessUid

        // dispatch({
        //     type: 'SET_IMAGE_BEGIN',
        //     data: null
        // })

        if(prevImgPath) firebase.deleteFile(prevImgPath)

        if (imgType === 'user_avatar') {
            if (imgFile) {
                firebase.uploadFile(
                    `images/${uid}/`,
                    imgFile
                ).then(snapshot => {
                    const updateDoc = firestore.collection("users").doc(uid);
                    const imgPath = snapshot.uploadTaskSnapshot.metadata.fullPath
                    firestore.runTransaction(t => {
                        return t.get(updateDoc)
                            .then(doc => {
                                t.set(updateDoc, {
                                    'avatarPath': imgPath,
                                    lastUpdated: new Date()
                                }, { merge: true });
                            })
                    })
                }).catch(err => {
                    console.log(err)
                })
            }
        }

        if (imgType === 'business_avatar') {
            if (imgFile) {
                firebase.uploadFile(
                    `images/${uid}/`,
                    imgFile
                ).then(snapshot => {
                    const updateDoc = firestore.collection("business").doc(businessUid);
                    const imgPath = snapshot.uploadTaskSnapshot.metadata.fullPath
                    firestore.runTransaction(t => {
                        return t.get(updateDoc)
                            .then(doc => {
                                t.set(updateDoc, {
                                    'avatarPath': imgPath,
                                    lastUpdated: new Date()
                                }, { merge: true });
                            })
                    })
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    }
}

export const getImage = (imgPath) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        const firebase = getFirebase()

        dispatch({
            type: 'GET_IMAGE_LOADING',
        })

        if (imgPath) {
            const pathRef = firebase.storage().ref(imgPath)

            return pathRef.getDownloadURL().then(url => {
                dispatch({
                    type: 'GET_IMAGE',
                    payload: {
                        imgPath: imgPath,
                        imgUrl: url
                    }
                })
            }).catch(err => {
                dispatch({
                    type: 'GET_IMAGE_ERROR',
                    payload: err
                })
            })
        }
    }
}