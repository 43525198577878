import {
  CREATE_SERVICE_BEGIN,
  CREATE_SERVICE,
  CREATE_SERVICE_ERROR,
  MODIFY_SERVICE_BEGIN,
  MODIFY_SERVICE,
  MODIFY_SERVICE_ERROR,
  EMPLOYEE_LINK_SERVICE_BEGIN,
  EMPLOYEE_LINK_SERVICE,
  EMPLOYEE_LINK_SERVICE_ERROR,
  SET_DISCLAIMER_BEGIN,
  SET_DISCLAIMER,
  SET_DISCLAIMER_ERROR,
} from "../actions/ServiceActions";

const initialState = {};

const serviceReducer = function (state = initialState, action) {
  switch (action.type) {
    case CREATE_SERVICE_BEGIN:
      return {
        ...state,
        createServiceLoading: true
      }
    case CREATE_SERVICE:
      return {
        ...state,
        data: action.data,
        createServiceLoading: false
      }
    case CREATE_SERVICE_ERROR: {
      return {
        ...state,
        error: action.err,
        createServiceLoading: false
      };
    }
    case MODIFY_SERVICE_BEGIN: {
      return {
        ...state,
        data: action.data,
        modifyServiceLoading: true
      };
    }
    case MODIFY_SERVICE: {
      return {
        ...state,
        data: action.data,
        modifyServiceLoading: false
      };
    }
    case MODIFY_SERVICE_ERROR: {
      return {
        ...state,
        error: action.err,
        modifyServiceLoading: false
      };
    }
    case SET_DISCLAIMER_BEGIN: {
      return {
        ...state,
        data: action.data,
        setDisclaimerLoading: true
      };
    }
    case SET_DISCLAIMER: {
      return {
        ...state,
        data: action.data,
        setDisclaimerLoading: false
      };
    }
    case SET_DISCLAIMER_ERROR: {
      return {
        ...state,
        error: action.err,
        setDisclaimerLoading: false
      };
    }
    case EMPLOYEE_LINK_SERVICE_BEGIN: {
      return {
        ...state,
        data: action.data,
        employeeLinkServiceLoading: true
      };
    }
    case EMPLOYEE_LINK_SERVICE: {
      return {
        ...state,
        data: action.data,
        employeeLinkServiceLoading: false
      };
    }
    case EMPLOYEE_LINK_SERVICE_ERROR: {
      return {
        ...state,
        error: action.err,
        employeeLinkServiceLoading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default serviceReducer;
