import React, { Component, Fragment } from "react";
import {
    Dialog,
    Button,
    Grid,
    FormControlLabel,
    Switch,
    TextField,
    CircularProgress
} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

const TermsDialog = (props) => {

    let { open, handleClose } = props;

    return (
        <Dialog onClose={handleClose} open={open}>
            <div className="p-6">
                <div className='flex items-center justify-between'>
                    <h6 className="mb-1">Terms of service</h6>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.handleClose()}
                    >
                        Done
                </Button>
                </div>
                <p>
                This website is owned and operated by Buksa. By accessing and/or using this website and related services, you agree to these Terms and Conditions, which include our Privacy Policy. Please review our Privacy Policy and these Terms and Conditions carefully and cease using our website if you do not agree to any of these Terms.
                <br/>
                <br/>In these Terms, 'us', 'we' and 'our' refers to Buksa. Stripe refers to Stripe, Inc.
                <br/>
                <br/>We will provide you access to our business management software (‘business solution’) without monthly fees.  Our integrated payments solution allows consumers to find your business via a native phone application and pay for services received. We charge a 4.9% flat fee on all payments processed via our platform, which is all inclusive and covers the cost of payment processing, hosting & technology costs and advertising fees.
                <br/>
                <br/>You must be a registered member to access certain features of our website. 
                <br/>
                <br/>As part of registering your account, you will also be asked to create a password. It is your responsibility to keep this password secure. You are responsible for all activity carried out under your user account. 
                <br/>
                <br/>When you register and activate your account, you will provide us with personal information such as your name and email address. Ensure that this information is current and accurate. All personal information which is collected will be handled in accordance to our Privacy Policy. 
                <br/>
                <br/>We collect personal information in order to complete your registration and for purposes established in our Privacy Policy. 
                <br/>
                <br/>We may partially disclose that information to third parties that help us deliver our services or as required by law. If you do not provide this information, we may not be able to provide you with all of our services. 
                <br/>
                <br/>If you are under the age of 13 years, you may not create an account or register as a member. If you are 13 or older but under the age of 18, you represent that you have reviewed these Terms with your parent or legal guardian to make sure that you and your parent or legal guardian understand these Terms. If you are a parent or guardian permitting a person under the age of 18 to create an account, you agree to: 
                <br/>
                <br/>- exercise supervision over the Minor's use of our website and account; 
                <br/>
                <br/>- assume all risks associated with the Minor's use of our website and their account, including the transmission of content or information to and from third parties via the Internet; 
                <br/>
                <br/>- ensure that the content and information that the Minor may encounter on our website are suitable for the Minor; 
                <br/>
                <br/>- assume liabilities resulting from the Minor's use of our website and their account; 
                <br/>
                <br/>- ensure the accuracy and truthfulness of all information submitted by the Minor; and 
                <br/>
                <br/>- provide the consents contained in these Terms on behalf of the Minor. 
                <br/>
                <br/>We may ask you to confirm that you have your parent's or guardian's permission and that they have agreed to these Terms on your behalf, and, even if we do not do this, we will assume that this is the case and will provide access to our website and your account on this basis. 
                <br/>
                <br/>By providing your personal information to us, you consent to the collection, use and storage of that information as described in the Privacy Policy and these Terms. 
                <br/>
                <br/>Our Privacy Policy explains: (i) how we store and use, and how you may access and correct your personal information; (ii) how you can lodge a complaint regarding the handling of your personal information; and (iii) how we will handle any complaint. If you would like any further information about our privacy policies or practices, please contact us via the message submission on our website. 
                <br/>
                <br/>1. Accuracy, completeness and timeliness of information
                <br/>
                <br/>The information on our website is not comprehensive and is intended to provide a summary of the subject matter covered. While we use all reasonable attempts to ensure the accuracy and completeness of the information on our website, to the extent permitted by law, including the Australian Consumer Law, we make no warranty regarding the information on this website. You should monitor any changes to the information contained on this website. 
                <br/>
                <br/>We are not liable to you or anyone else if interference with or damage to your computer systems occurs in connection with the use of this website or a linked website. You must take your own precautions to ensure that whatever you select for your use from our website is free of viruses or anything else (such as worms or Trojan horses) that may interfere with or damage the operations of your computer systems. 
                <br/>
                <br/>We may, from time to time and without notice, change or add to the website (including the Terms) or the information, products or services described in it. However, we do not undertake to keep the website updated. We are not liable to you or anyone else if errors occur in the information on the website or if that information is not up-to-date. 
                <br/>
                <br/>Unless otherwise indicated, we own or license from third parties all rights, title and interest (including copyright, designs, patents, trademarks and other intellectual property rights) in this website and in all of the material (including all text, graphics, logos, audio and software) made available on this website. 
                <br/>
                <br/>Your use of this website and use of and access to any Content does not grant or transfer any rights, title or interest to you in relation to this website or the Content. However we do grant you a licence to access the website and view the Content on the terms and conditions set out in this Agreement and, where applicable, as expressly authorised by us and/or our third party licensors. 
                <br/>
                <br/>Any reproduction or redistribution of this website or the Content is prohibited and may result in civil and criminal penalties. In addition, you must not copy the Content to any other server, location or support for publication, reproduction or distribution is expressly prohibited. 
                <br/>
                <br/>All other use, copying or reproduction of this website, the Content or any part of it is prohibited, except to the extent permitted by law. 
                <br/>
                <br/>2. Unacceptable activity
                <br/>
                <br/>You must not do any act that we would deem to be inappropriate, is unlawful or is prohibited by any laws applicable to our website, including but not limited to: 
                <br/>
                <br/>- any act that would constitute a breach of either the privacy (including uploading private or personal information without an individual's consent) or any other of the legal rights of individuals; 
                <br/>
                <br/>- using this website to defame or libel us, our employees or other individuals; 
                <br/>
                <br/>- uploading files that contain viruses that may cause damage to our property or the property of other individuals; 
                <br/>
                <br/>- posting or transmitting to this website any non-authorised material including, but not limited to, material that is, in our opinion, likely to cause annoyance, or which is defamatory, racist, obscene, threatening, pornographic or otherwise or which is detrimental to or in violation of our systems or a third party's systems or network security. 
                <br/>
                <br/>If we allow you to post any information to our website, we have the right to take down this information at our sole discretion and without notice. 
                <br/>
                <br/>3. Warranties and disclaimers
                <br/>
                <br/>To the maximum extent permitted by law, including the Australian Consumer Law, we make no warranties or representations about this website or the Content, including but not limited to warranties or representations that they will be complete, accurate or up-to-date, that access will be uninterrupted or error-free or free from viruses, or that this website will be secure. 
                <br/>
                <br/>We reserve the right to restrict, suspend or terminate without notice your access to this website, any Content, or any feature of this website at any time without notice and we will not be responsible for any loss, cost, damage or liability that may arise as a result. 
                <br/>
                <br/>4. Liability 
                <br/>
                <br/>To the maximum extent permitted by law, including the Australian Consumer Law, in no event shall we be liable for any direct and indirect loss, damage or expense – irrespective of the manner in which it occurs – which may be suffered due to your use of our website and/or the information or materials contained on it, or as a result of the inaccessibility of this website and/or the fact that certain information or materials contained on it are incorrect, incomplete or not up-to-date. 
                <br/>
                <br/>5. Jurisdiction and governing law 
                <br/>
                <br/>Your use of the website and these Terms are governed by the law of Victoria, Australia and you submit to the non-exclusive jurisdiction of the courts exercising jurisdiction in Victoria, Australia.
                <br/>
                <br/>5. Stripe
                <br/>
                <br/>We partner with Stripe to process payments. For a copy of Stripe’s Terms, Conditions & Privacy policy, please visit https://stripe.com/au/privacy
                <br/>
                <br/>
                <br/>
                <br/>Privacy
                <br/>
                <br/>
                <br/>
                <br/>In this Privacy Policy, 'us' 'we' or 'our' means Buksa. Our Privacy Policy sets outs out how we collect, use, store and disclose your personal information. We are committed to respect your privacy. 
                <br/>
                <br/>Personal information may include information about an individual that is reasonably identifiable. This may include your name, age, gender, postcode and contact details. 
                <br/>
                <br/>Through providing personal information to us, you consent to our collection, use and disclosure of your personal information in accordance with this Privacy Policy and any other arrangements that apply between us. We may change our Privacy Policy every so often through publishing changes to it on our website. We encourage you to check our website periodically to ensure that you are aware of our current Privacy Policy. 
                <br/>
                <br/>What type of personal information is collected? 
                <br/>
                <br/>We may collect the following personal information: 
                <br/>
                <br/>- name;
                <br/>
                <br/>- email address; 
                <br/>
                <br/>- telephone number and other contact details; 
                <br/>
                <br/>- date of birth or age; 
                <br/>
                <br/>- standard tech information such as your device ID, device type, computer and connection information, geo-location information, statistics on page views, traffic to and from the site, ad data, IP address and standard web log information; 
                <br/>
                <br/>- information you provide to us via surveys; 
                <br/>
                <br/>- details of services we have provided or that you have inquired about. This includes any additional information necessary to deliver those services and answer your requests and inquiries; 
                <br/>
                <br/>- any additional information relating to you that you provide to us directly through our website or app or indirectly through your use of our website or app or online presence or through other websites or accounts from which you permit us to collect information; 
                <br/>
                <br/>- any other personal information which may be required to facilitate your dealings with us. 
                <br/>
                <br/>We also collect anonymised cookie information in order to enable Google Adwords remarketing and use it to trigger non-intrusive, relevant ads across the Google Content Network. 
                <br/>
                <br/>We may gather this personal information either from you directly, or via third parties when you: 
                <br/>
                <br/>- register on our website; 
                <br/>
                <br/>- communicate with us through correspondence, email, chat, or when you share information with us from other social applications, services or websites; 
                <br/>
                <br/>- interact with our sites, services, content and advertising; or 
                <br/>
                <br/>Additionally, when you apply for a job or position with us we may collect certain information from you (including your name, contact details, working history and relevant records checks) from any recruiter, your previous employers and others who may be able to provide information to us to assist in our decision on whether or not to make you an offer of employment or engage you under a contract. This Privacy Policy does not apply to acts and practices in relation to employee records of our current and former employees, which are exempt from the Privacy Act. 
                <br/>
                <br/>Why do we collect, use and disclose personal information?
                <br/>
                <br/>We may collect, hold, use and disclose your personal information for the following purposes: 
                <br/>
                <br/>- to enable you to access and use our website;
                <br/>
                <br/>- to send you service, support and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you; 
                <br/>
                <br/>- to operate, protect, improve and optimise our website, business and our users’ experience, such as to perform analytics, conduct research and for advertising and marketing; 
                <br/>
                <br/>- to comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties; and 
                <br/>
                <br/>- to consider your application for employment.
                <br/>
                <br/>
                <br/>
                </p>
            </div>
        </Dialog>
    );
}

export default TermsDialog