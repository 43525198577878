import React from 'react'

const prodCreateBusinessRoutes = [
    {
        key: 'createbusiness',
        path: '/createbusiness',
        component: React.lazy(() => import('../CreateBusiness/CreateBusinessLayout')),
        auth: 'nbl_owner',
        exact: false,
        breadcrumbNameOwner: 'Create business',
        breadcrumbNameEmployee: 'Create business'
    },
    {
        key: 'editbusiness',
        path: '/editbusiness',
        component: React.lazy(() => import('../CreateBusiness/CreateBusinessLayout')),
        auth: 'owner',
        exact: false,
        breadcrumbNameOwner: 'Business settings',
        breadcrumbNameEmployee: 'Business settings'
    }
];

export default prodCreateBusinessRoutes;
