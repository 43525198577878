import React from 'react'

const prodOfferRoutes = [
    {
        key: 'offermanagement',
        path: '/offermanagement',
        component: React.lazy(() => import('../EditOffer/EditOffer')),
        auth: 'owner',
        exact: false,
        breadcrumbNameOwner: 'My Business / Offers',
        breadcrumbNameEmployee: 'Offers'
    }
];

export default prodOfferRoutes;