import React from 'react'

const prodRedirectRoutes = [
    {
        key: 'redirect_payments',
        path: '/redirect/payments/onboardcompletion',
        component: React.lazy(() => import('../Redirects/PaymentsRedirect')),
        exact: false,
        //breadcrumbNameOwner: 'My Business / Payments',
        //breadcrumbNameEmployee: 'Payments'
    },
];

export default prodRedirectRoutes;