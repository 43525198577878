import React from "react";
import { Redirect } from "react-router-dom";

import prodRedirectRoutes from './prodRedirectRoutes'
import prodPaymentsRoutes from './prodPaymentsRoutes'
import prodSessionRoutes from "./prodSessionRoutes";
import prodCreateBusinessRoutes from './prodCreateBusinessRoutes'
import prodSelectBusinessRoutes from './prodSelectBusinessRoutes'
import prodEditServicesRoutes from './prodEditServicesRoutes'
import prodInviteRoutes from './prodInviteRoutes'
import prodEmployeeRosterRoutes from './prodEmployeeRosterRoutes'
import prodOfferRoutes from './prodOfferRoutes'
import prodCalendarRoutes from './prodCalendarRoutes'
import prodDashboardRoutes from './prodDashboardRoutes'
import prodEditProfileRoutes from './prodEditProfileRoutes'
import NotFound from '../prodSessions/NotFound'

const errorRoute = [
  {
    key: '404',
    component: NotFound
  }
];

const routes = [
  ...prodDashboardRoutes,
  ...prodCalendarRoutes,
  ...prodPaymentsRoutes,
  ...prodOfferRoutes,
  ...prodEmployeeRosterRoutes,
  ...prodInviteRoutes,
  ...prodEditServicesRoutes,
  ...prodCreateBusinessRoutes,
  ...prodEditProfileRoutes,
  ...prodRedirectRoutes,
  ...prodSelectBusinessRoutes,
  ...prodSessionRoutes,
  ...errorRoute
];

export default routes;