import history from "history.js";

export const LOAD_EMPLOYEE_CONTRACT = 'LOAD_EMPLOYEE_CONTRACT'
export const UNLOAD_EMPLOYEE_CONTRACT = 'UNLOAD_EMPLOYEE_CONTRACT'
export const EMPLOYEE_SET_HOURS = 'EMPLOYEE_SET_HOURS'
export const EMPLOYEE_SET_HOURS_ERROR = 'EMPLOYEE_SET_HOURS_ERROR'

export const loadEmployeeContract = (contract) => {
    return (dispatch) => {
        //console.log(business)
        history.push({ pathname: '/' })
        dispatch({
            type: 'LOAD_EMPLOYEE_CONTRACT',
            loadedEmployeeContract: contract
        })

    }
}

export const unloadEmployeeContract = () => {
    return (dispatch) => {
        //console.log(business)
        dispatch({
            type: 'UNLOAD_EMPLOYEE_CONTRACT'
        })

    }
}

export const employeeSetHours = (contractId, hours) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()
        firestore.runTransaction(t => {
            const docRef = firestore.collection("contracts").doc(contractId);
            return t.get(docRef)
                .then(doc => {
                    t.update(docRef, {
                        'workingHours': hours
                    })
                })
        })
            .then((resp) => {
                dispatch({
                    type: 'EMPLOYEE_SET_HOURS',
                    data: resp
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'EMPLOYEE_SET_HOURS_ERROR',
                    err: err
                })
            })
    }
}