import "../styles/_app.scss";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from "react";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import history from "history.js";
import { Router } from "react-router-dom";
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { connect } from "react-redux";
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import prodRootRoutes from './prodViews/routes/prodRootRoutes'
import AppSuspense from './prodViews/Suspense/AppSuspense'
import CustomSuspense from './prodViews/Suspense/CustomSuspense'
import NavBar from './prodViews/Layout/NavBar'
import TransparentHeader from './prodViews/Layout/TransparentHeader'
import { loadOwnerBusiness } from './redux/actions/BusinessActions'
import { loadEmployeeContract } from './redux/actions/ContractActions'
//import ReactGA from 'react-ga';

const appInitialise = [
  {
    component: CustomSuspense
  }
]

const App = (props) => {
  const {
    auth,
    profile,
    loadedOwnerBusinessUid,
    loadedEmployeeContractUid } = props

  const userType = profile.userType

  // // Google Analytics
  // const trackingId = 'UA-150436860-3'
  // ReactGA.initialize(trackingId)
  // ReactGA.set({
  //   userId: auth.uid,
  //   userType: userType,
  //   loadedOwnerBusinessUid,
  //   loadedEmployeeContractUid
  //   // any data that is relevant to the user session
  //   // that you would like to track with google analytics
  // })

  // history.listen(location => {
  //   ReactGA.set({ page: location.pathname }); // Update the user's current page
  //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
  // });

  const pageEligibility = () => {
    if (auth.isLoaded && auth.uid && userType) {
      if (userType == 'owner' && !loadedOwnerBusinessUid) return ['nbl_owner', 'nbl_any']
      if (userType == 'employee' && !loadedEmployeeContractUid) return ['nbl_employee', 'nbl_any']
      if (userType == 'owner') return ['owner', 'any']
      if (userType == 'employee') return ['employee', 'any']
    }
    if (auth.isLoaded && !auth.uid) {
      return ['logout']
    }
    else return null
  }

  const loadDefaultBusiness = () => {
    const defaultBusiness = props.ownerBusinessList && props.ownerBusinessList[0]
    return props.ownerBusinessList && props.ownerBusinessList.length === 1 ? props.loadOwnerBusiness(defaultBusiness) : null
  }

  const loadDefaultContract = () => {
    const defaultContract = props.employeeBusinessList && props.employeeBusinessList[0]
    return props.employeeBusinessList && props.employeeBusinessList.length === 1 && props.employeeInvitations && props.employeeInvitations.length === 0 ? props.loadEmployeeContract(defaultContract) : null
  }

  const ActiveRoutes = pageEligibility() ? (prodRootRoutes.filter(route => {
    return pageEligibility().includes(route.auth) || !route.auth
  }).map(route => {
    if (route.path && route.exact) return <Route exact path={route.path} component={route.component} key={route.key} />
    if (route.path && !route.exact) return <Route path={route.path} component={route.component} key={route.key} />
    if (!route.path) return <Route component={route.component} key={route.key} />
  })
  ) : <CustomSuspense />

  // useEffect(() => {
  //   if (userType === 'owner') loadDefaultBusiness()
  //   if (userType === 'employee') loadDefaultContract()
  // }, [userType, props.ownerBusinessList, props.employeeBusinessList]);

  return (  // removed Auth and AuthGuard
    <MatxTheme>
      <AppSuspense>
        <Router history={history}>
          {props.firestoreIsLoading ? (
            <Fragment>
              <CustomSuspense />
            </Fragment>
          ) : (
              props.auth.uid && (props.loadedOwnerBusinessUid || props.loadedEmployeeContractUid) ? (
                <NavBar>
                  <Switch>
                    {ActiveRoutes}
                  </Switch>
                </NavBar>
              ) : (
                  <TransparentHeader>
                    <Switch>
                      {ActiveRoutes}
                    </Switch>
                  </TransparentHeader>
                )
            )}
        </Router>
      </AppSuspense>
    </MatxTheme>
  );
};

const mapStateToProps = state => {

  const userType = state.firebase.profile.userType

  const firestoreLoadingSnapshots = state.snapshot && state.snapshot.isLoading && state.snapshot.isLoading.length
  const firestoreIsLoading = firestoreLoadingSnapshots > 0 ? true : false

  return {
    state: state,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    loadedOwnerBusinessUid: state.business.loadedOwnerBusinessUid,
    loadedEmployeeContractUid: state.contract.loadedEmployeeContractUid,
    ownerBusinessList: state.firestore.ordered.ownerBusinessList,
    employeeBusinessList: state.firestore.ordered.employeeBusinessList,
    employeeInvitations: state.firestore.ordered.employeeInvitations,
    firestoreIsLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadOwnerBusiness: business => {
      dispatch(loadOwnerBusiness(business))
    },
    loadEmployeeContract: contract => {
      dispatch(loadEmployeeContract(contract))
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [
      {
        collection: 'business', //Used for list of businesses owned
        storeAs: 'ownerBusinessList',
        where: [['ownerUid', '==', props.state.firebase.auth.uid ? props.state.firebase.auth.uid : 'no_auth']],
        orderBy: [
          ['businessDisplayName', 'asc']
        ]
      },
      {
        collection: 'contracts', //Used for list of businesses owned
        storeAs: 'employeeBusinessList',
        where: [
          ['employeeUid', '==', props.state.firebase.auth.uid ? props.state.firebase.auth.uid : 'no_auth'],
          ['contractActive', '==', true]
        ],
        // orderBy: [
        //   ['businessDisplayName', 'asc']
        // ]
      },
      {
        collection: 'business', //Return a list of every business
        storeAs: 'allBusinessList',
        where: [
          ['businessActive', '==', true]
        ]
      },
      {
        collection: 'invitations', //Return a list to the employee of invitations they have sent
        storeAs: 'employeeInvitations',
        where: [
          ['employeeUid', '==', props.state.firebase.auth.uid ? props.state.firebase.auth.uid : 'no_auth'],
          ['inviteActive', '==', true]
        ],
      },
      {
        collection: 'invitations', //Return a list to the business owner of invitations received
        storeAs: 'businessInvitations',
        where: [
          ['businessUid', '==', props.state.business.loadedOwnerBusinessUid ? props.state.business.loadedOwnerBusinessUid : 'no_business'],
          ['inviteActive', '==', true]
        ]
      },
      {
        collection: 'contracts', //Return a list to the business owner of invitations received
        storeAs: 'businessContracts',
        where: [
          ['businessUid', '==', props.state.business.loadedOwnerBusinessUid ? props.state.business.loadedOwnerBusinessUid : 'no_business'],
          ['contractActive', '==', true]
        ]
      },
      {
        collection: 'contracts', //Return a list to the business owner of invitations received
        storeAs: 'employeeContracts',
        where: [
          ['businessUid', '==', props.state.contract.loadedEmployeeBusinessUid ? props.state.contract.loadedEmployeeBusinessUid : 'no_business'],
          ['contractActive', '==', true]
        ]
      },
      {
        collection: 'offer', //Return a list to the business owner of invitations received
        storeAs: 'businessOffers',
        where: [
          ['businessUid', '==', props.state.business.loadedOwnerBusinessUid ? props.state.business.loadedOwnerBusinessUid : 'no_business']
        ]
      },
      {
        collection: 'offer', //Return a list to the business owner of invitations received
        storeAs: 'employeeOffers',
        where: [
          ['businessUid', '==', props.state.contract.loadedEmployeeBusinessUid ? props.state.contract.loadedEmployeeBusinessUid : 'no_business']
        ]
      },
      {
        collection: 'event', //Return a list to the business owner of invitations received
        storeAs: 'businessEvents',
        where: [
          ['businessUid', '==', props.state.business.loadedOwnerBusinessUid ? props.state.business.loadedOwnerBusinessUid : 'no_business']
        ]
      },
      {
        collection: 'event', //Return a list to the business owner of invitations received
        storeAs: 'employeeEvents',
        where: [
          ['contractUid', 'array-contains', props.state.contract.loadedEmployeeContractUid ? props.state.contract.loadedEmployeeContractUid : 'no_business']
        ]
      },
      {
        collection: 'service', //Return a list to the business owner of invitations received
        storeAs: 'businessServices',
        where: [
          ['businessUid', '==', props.state.business.loadedOwnerBusinessUid ? props.state.business.loadedOwnerBusinessUid : 'no_business']
        ],
        orderBy: [
          ['serviceCategory', 'asc'],
          ['serviceName', 'asc']
        ]
      },
      {
        collection: 'service', //Return a list to the business owner of invitations received
        storeAs: 'employeeServices',
        where: [
          ['businessUid', '==', props.state.contract.loadedEmployeeBusinessUid ? props.state.contract.loadedEmployeeBusinessUid : 'no_business']
        ],
        orderBy: [
          ['serviceCategory', 'asc'],
          ['serviceName', 'asc']
        ]
      },
      {
        collection: 'business', //Return a list to the business owner of invitations received
        storeAs: 'loadedBusiness',
        where: [
          [props.firebase.firestore.FieldPath.documentId(), '==', props.state.business.loadedOwnerBusinessUid ? props.state.business.loadedOwnerBusinessUid : 'no_business']
        ]
      },
      {
        collection: 'businessPrivateInfo', //Return a list to the business owner of invitations received
        storeAs: 'loadedBusinessPrivateInfo',
        where: [
          [props.firebase.firestore.FieldPath.documentId(), '==', props.state.business.loadedOwnerBusinessUid ? props.state.business.loadedOwnerBusinessUid : 'no_business']
        ]
      },
      {
        collection: 'contracts', //Return a list to the business owner of invitations received
        storeAs: 'loadedContract',
        where: [
          [props.firebase.firestore.FieldPath.documentId(), '==', props.state.contract.loadedEmployeeContractUid ? props.state.contract.loadedEmployeeContractUid : 'no_business']
        ]
      },
      {
        collection: 'disclaimer', //Return a list to the business owner of invitations received
        storeAs: 'businessDisclaimer',
        where: [
          [props.firebase.firestore.FieldPath.documentId(), '==', props.state.business.loadedOwnerBusinessUid ? props.state.business.loadedOwnerBusinessUid : 'no_business']
        ]
      }
    ]
  })
)
  (App);
