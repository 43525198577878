import history from "history.js";
import instance from '../../services/firebase/firebaseAuthService'

export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";

export const RESET_PASSWORD = "RESET_PASSWORD";

export const CREATE_USER_LOADING = 'CREATE_USER_LOADING'
export const CREATE_USER_AUTH_SUCCESS = 'CREATE_USER_AUTH_SUCCESS'
export const CREATE_USER_AUTH_ERROR = 'CREATE_USER_AUTH_ERROR'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR_DB = 'CREATE_USER_ERROR_DB'

export const MODIFY_USER_LOADING = 'MODIFY_USER_LOADING'

export const MODIFY_USER_EMAIL = 'MODIFY_USER_EMAIL'
export const MODIFY_USER_EMAIL_ERROR = 'MODIFY_USER_EMAIL_ERROR'

export const MODIFY_USER_PASSWORD = 'MODIFY_USER_PASSWORD'
export const MODIFY_USER_PASSWORD_ERROR = 'MODIFY_USER_PASSWORD_ERROR'

export const MODIFY_USER_DB = 'MODIFY_USER_DB'
export const MODIFY_USER_DB_ERROR = 'MODIFY_USER_DB_ERROR'

export const MODIFY_USER_AUTH_ERROR = 'MODIFY_USER_AUTH_ERROR'

export function logoutUser() {
  console.log('disp logout')
  return dispatch => {
    instance.signOut()
      .then(() => {

        dispatch({
          type: 'USER_LOGGED_OUT'
        });

        history.push({
          pathname: "/session/signin"
        });

        window.location.reload(false);

      })
      .catch(err => {
        return null
      })
  };
}

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()

    dispatch({
      type: 'CREATE_USER_LOADING'
    });

    instance.signUpWithEmailAndPassword(
      newUser.email,
      newUser.password
    )
      .then(resp => {

        const userId = resp.user.uid

        return firestore
          .collection('users')
          .doc(userId)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            displayNameFull: newUser.firstName + ' ' + newUser.lastName,
            displayNameShort: newUser.firstName + ' ' + newUser.lastName.substring(0, 1),
            initials: newUser.firstName.substring(0, 1).toUpperCase() + newUser.lastName.substring(0, 1).toUpperCase(),
            email: newUser.email,
            firstIntent: newUser.intent,
            userType: newUser.intent,
            createdAt: new Date(),
            lastUpdated: new Date()
          }).then(docRef => {
            firestore.collection('users').doc(userId)
              .get()
              .then(doc => {
                const userDoc = doc.data()

                let inviteRef = firestore.collection('invitations');

                inviteRef
                  .where('inviteEmail', '==', newUser.email)
                  .where('inviteActive', '==', true)
                  .get()
                  .then(snapshot => {
                    console.log(snapshot)
                    if (!snapshot.empty) {
                      snapshot.forEach(doc => {
                        const inviteId = doc.id
                        firestore.collection("invitations").doc(inviteId).update({
                          employeeUid: userId,
                          employeeData: userDoc,
                          lastUpdated: new Date()
                        })
                      })
                    }
                  })
                  .then(resp => {
                    history.push({ pathname: '/' })
                    return dispatch({
                      type: 'CREATE_USER_SUCCESS'
                    })
                  })
                  .catch(err => {
                    dispatch({
                      type: 'CREATE_USER_ERROR_DB'
                    })
                  })
              })
          })
      })
      .catch(err => {
        dispatch({
          type: 'CREATE_USER_AUTH_ERROR',
          payload: err
        })
      })
  }
}

export const signIn = (userCredentials) => {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });
    instance.signInWithEmailAndPassword(
      userCredentials.email,
      userCredentials.password
    ).then(resp => {
      const { user } = resp
      if (user.uid) {
        history.push({ pathname: '/' })
        return dispatch({
          type: LOGIN_SUCCESS,
          loading: false
        })
      } else {
        return dispatch({
          type: LOGIN_ERROR,
          payload: "Login Failed"
        })
      }
    }
    ).catch(err => {
      return dispatch({
        type: LOGIN_ERROR,
        payload: err
      })
    })
  }
}

export const resetPassword = email => {
  return dispatch => {
    instance.resetPassword(email)
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
    history.push({ pathname: '/session/reset-email-sent' })
  };
}

export const modifyProfile = (userDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const uid = getState().firebase.auth.uid
    const currentEmail = getState().firebase.profile.email

    dispatch({
      type: 'MODIFY_USER_LOADING'
    });

    instance.reauthUser(
      currentEmail,
      userDetails.currentPassword
    ).then(() => {

      instance.updateEmail(userDetails.email).then(resp => {
        dispatch({
          type: 'MODIFY_USER_EMAIL'
        })
      }).catch(err => {
        dispatch({
          type: 'MODIFY_USER_EMAIL_ERROR',
          payload: err
        })
      })

      userDetails.password && userDetails.password.length > 0 && instance.updatePassword(userDetails.password).then(resp => {
        dispatch({
          type: 'MODIFY_USER_EMAIL_PASSWORD'
        })
      }).catch(err => {
        dispatch({
          type: 'MODIFY_USER_PASSWORD_ERROR',
          payload: err
        })
      })

      const userId = uid

      return firestore
        .collection('users')
        .doc(userId)
        .set({
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          displayNameFull: userDetails.firstName + ' ' + userDetails.lastName,
          displayNameShort: userDetails.firstName + ' ' + userDetails.lastName.substring(0, 1),
          initials: userDetails.firstName.substring(0, 1).toUpperCase() + userDetails.lastName.substring(0, 1).toUpperCase(),
          email: userDetails.email,
          lastUpdated: new Date()
        }, { merge: true })
        .then(resp => {
          return dispatch({
            type: 'MODIFY_USER_DB'
          })
        })
        .catch(err => {
          dispatch({
            type: 'MODIFY_USER_DB_ERROR'
          })
        })
    })
      .catch(err => {
        dispatch({
          type: 'MODIFY_USER_AUTH_ERROR',
          payload: err
        })
      })
  }
}