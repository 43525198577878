import {
  LOAD_EMPLOYEE_CONTRACT,
  UNLOAD_EMPLOYEE_CONTRACT,
  EMPLOYEE_SET_HOURS,
  EMPLOYEE_SET_HOURS_ERROR
} from "../actions/ContractActions";

const initialState = {};

const contractReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOAD_EMPLOYEE_CONTRACT: {
      return {
        ...state,
        loadedEmployeeContractUid: action.loadedEmployeeContract.id,
        loadedEmployeeBusinessUid: action.loadedEmployeeContract.businessUid
      }
    }
    case UNLOAD_EMPLOYEE_CONTRACT: {
      return {
        ...state,
        loadedEmployeeContractUid: null,
        loadedEmployeeBusinessUid: null
      }
    }
    case EMPLOYEE_SET_HOURS: {
      return {
        ...state,
        data: action.data
      }
    }
    case EMPLOYEE_SET_HOURS_ERROR: {
      return {
        ...state,
        error: action.err
      }
    }
    default: {
      return state;
    }
  }
};

export default contractReducer;