import React, { Component, Fragment } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  Radio,
  RadioGroup,
  CircularProgress
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { signUp, modifyProfile } from '../../redux/actions/UserActions'
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { getReferralInvitation, clearReferral } from '../../redux/actions/InviteActions'
import CustomSuspense from '../Suspense/CustomSuspense'
import { LoadingButtonTypeSubmit } from '../Shared/Buttons'
import CustomAvatar from '../Avatars/CustomAvatar'
import CreateIcon from '@material-ui/icons/Create';
import TermsDialog from './TermsDialog'
//import ReactGA from 'react-ga';

class SignUp extends Component {

  constructor(props) {
    super(props)

    this.getReferralData()

    let { uid, profile } = this.props

    const initState = uid ? (
      {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        currentPassword: '',
        password: '',
        intent: profile.userType,
        avatarImg: '',
        invitationType: '',
        showTerms: false
      }
    ) : (
      {
        firstName: '',
        lastName: '',
        email: '',
        currentPassword: '',
        password: '',
        intent: '',
        avatarImg: '',
        invitationType: '',
        showTerms: false
      }
    )

    this.state = initState
  }

  static getDerivedStateFromProps(props, state) {
    let { referralData } = props
    let { email, invitationType } = state
    if (referralData && (referralData.inviteEmail !== email || referralData.invitationType !== invitationType)) {
      return {
        email: referralData.inviteEmail,
        invitationType: referralData.invitationType,
        intent: referralData.invitationType === 'fromBusiness_withoutUser' ? 'employee' : ''
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.props.clearReferral()
  }

  getReferralId = () => {
    const pathname = this.props.history.location.pathname
    const pathIsReferral = pathname.includes('/session/refer')
    const pathArray = this.props.history.location.pathname.split('/')
    const referralId = pathIsReferral ? pathArray[pathArray.length - 1] : null
    return pathIsReferral ? referralId : null
  }

  getReferralData = () => {
    const referralId = this.getReferralId()
    return referralId ? this.props.getReferralInvitation(referralId) : null
  }

  handleChange = event => {
    event.persist();

    // // Google Analytics
    // ReactGA.event({
    //   category: 'Modify Form',
    //   action: event.target.id
    // });

    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleFormSubmit = event => {
    event.preventDefault()

    // Google Analytics
    // ReactGA.event({
    //   category: this.props.uid ? 'Edit Profile' : 'Conversion',
    //   action: this.props.uid ? 'Modified User Profile' : 'User sign up'
    // });

    this.props.uid ? this.props.modifyProfile(this.state) : this.props.signUp(this.state)
    //console.log(event, this.props, this.state)
  };

  render() {
    let { firstName, lastName, email, password, intent, currentPassword } = this.state;
    let { classes, user, referralData, loading, uid, profile } = this.props
    return (
      !loading ? (
        <div className="signup flex justify-center w-full h-full-screen">
          <div className="p-8">
            <Card className="signup-card position-relative y-center">
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="p-9 h-full">
                    {uid ? (
                      <Fragment>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justify="center"
                        >
                          <CustomAvatar
                            profile={profile}
                            reqAvatar={'user_avatar'}
                            isEditProfile={true}
                          />
                          <div className='py-3' />
                          <h5>EDIT PROFILE</h5>
                        </Grid>
                      </Fragment>
                    ) : <h5>Focus on what you do best, while Buksa takes care of the rest</h5>
                    }
                    <div className="py-2" />
                    <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                      <TextValidator
                        className="mb-6 w-full"
                        variant="outlined"
                        label="First Name"
                        onChange={this.handleChange}
                        type="text"
                        name="firstName"
                        value={firstName}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                      <TextValidator
                        className="mb-6 w-full"
                        variant="outlined"
                        label="Last Name"
                        onChange={this.handleChange}
                        type="text"
                        name="lastName"
                        value={lastName}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                      <TextValidator
                        className="mb-6 w-full"
                        variant="outlined"
                        label="Email"
                        onChange={this.handleChange}
                        type="email"
                        name="email"
                        value={email}
                        disabled={referralData ? true : false}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "This field is required",
                          "Email is not valid"
                        ]}
                      />
                      {uid ?
                        (<TextValidator
                          className="mb-4 w-full"
                          label="Current Password"
                          variant="outlined"
                          onChange={this.handleChange}
                          name="currentPassword"
                          type="password"
                          value={currentPassword}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                        />) : null
                      }
                      <TextValidator
                        className="mb-4 w-full"
                        label={uid ? 'New password (optional)' : 'Password'}
                        variant="outlined"
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        value={password}
                        validators={!uid ? ["required"] : []}
                        errorMessages={!uid ? ["This field is required"] : []}
                      />
                      {uid ? null : (
                        <Fragment>
                          <RadioGroup
                            className="mb-2"
                            value={intent}
                            name="intent"
                            onChange={this.handleChange}
                            row
                          >
                            <FormControlLabel
                              value="owner"
                              className="mb-4"
                              control={<Radio required={true} color="secondary" />}
                              label="I am a business owner (or have authority to act on behalf of a business)"
                              labelPlacement="end"
                              disabled={referralData ? true : false}
                            />
                            <FormControlLabel
                              value="employee"
                              className="mb-4"
                              control={<Radio required={true} color="secondary" />}
                              label="I am an employee and want to link myself to an existing business"
                              labelPlacement="end"
                              disabled={referralData ? true : false}
                            />
                          </RadioGroup>
                          {/* <FormControlLabel
                            className="mb-4"
                            name="agreement"
                            onChange={this.handleChange}
                            control={<Checkbox required={true} />}
                            label={
                              <Button
                                onClick={() => this.setState({ showTerms: true })}
                              >
                                <div className='text-left'>
                                  I have read and agree to the <u>terms of service & privacy policy</u>
                                </div>
                              </Button>
                            }
                          /> */}
                          <div className="mb-4">
                            <Button
                              onClick={() => this.setState({ showTerms: true })}
                            >
                              <div className='text-left'>
                                By clicking agree and continue below you agree to our <u>terms of service</u>
                              </div>
                            </Button>
                          </div>
                        </Fragment>
                      )}
                      {
                        user.signupError ? <Fragment><Typography color='error'>{user.signupError}</Typography><div className="py-2" /></Fragment> : null
                      }
                      {
                        user.modifyError ? <Fragment><Typography color='error'>{user.modifyError}</Typography><div className="py-2" /></Fragment> : null
                      }
                      <Fragment>
                        <LoadingButtonTypeSubmit
                          loading={this.props.user.loading}
                          primaryText={uid ? 'Save changes' : 'Agree and Continue'}
                          name='signup'
                          id='signup'
                          variant='contained'
                          color='primary'
                          type='submit'
                          className="w-full"
                        />
                      </Fragment>
                      {uid ? (
                        <Fragment>
                          <Button
                            className="w-full my-2"
                            variant='outlined'
                            onClick={() =>
                              this.props.history.push("/")
                            }
                          >
                            Cancel
                          </Button>
                        </Fragment>
                      ) : (
                        <Fragment>
                            <Button
                              className="w-full my-2"
                              variant='outlined'
                              onClick={() =>
                                this.props.history.push("/session/signin")
                              }
                            >
                              Already have an account? Sign in
                      </Button>
                        </Fragment>)
                      }
                    </ValidatorForm>
                  </div>
              </Grid>
              </Grid>
            </Card>
        </div>
          {
      this.state.showTerms && (
        <TermsDialog
          handleClose={() => this.setState({ showTerms: false })}
          open={this.state.showTerms}
        />
      )
    }
        </div >) : (<Fragment><CustomSuspense /></Fragment>)
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    referralData: state.invite.referralData,
    referralLoading: state.invite.loading,
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile
  }
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => {
      dispatch(signUp(newUser))
    },
    modifyProfile: userDetails => {
      dispatch(modifyProfile(userDetails))
    },
    getReferralInvitation: referralId => {
      dispatch(getReferralInvitation(referralId))
    },
    clearReferral: () => {
      dispatch(clearReferral())
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))
