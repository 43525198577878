import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import DashboardIcon from '@material-ui/icons/Dashboard';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import MailIcon from '@material-ui/icons/Mail';
import EventIcon from '@material-ui/icons/Event';


// {
//   name: "Select Business",
//     path: "/selectbusiness",
//       iconText: "A"
// },
// {
//   name: "Create Business",
//     path: "/createbusiness",
//       iconText: "B"
// },
// {
//   name: "Manage my services",
//     path: "/editservices",
//       iconText: "C"
// },
// {
//   name: "Employee services",
//     path: "/employeeservices",
//       iconText: "C"
// },
// {
//   name: "Manage invitations",
//     path: "/invitationmanagement",
//       iconText: "C"
// },
// {
//   name: "Manage availability",
//     path: "/manageavailability",
//       iconText: "C"
// },
// {
//   name: "Manage offers",
//     path: "/offermanagement",
//       iconText: "C"
// },
// {
//   name: "Calendar",
//     path: "/mycalendar",
//       iconText: "C"
// }

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    listItemText: {
        color: 'black',
    },
}));

const ProdNavListEmployee = props => {

    const classes = useStyles();
    const path = props.history.location.pathname

    const [selectedIndex, setSelectedIndex] = React.useState('/');

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        setSelectedIndex(path)
    })

    return (
        <List
            component="nav"
            className={classes.root}
        >

            <NavLink to='/'>
                <ListItem
                    button
                    selected={selectedIndex === '/'}
                    onClick={(event) => handleListItemClick(event, '/')}
                >
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Dashboard"
                        classes={{ primary: classes.listItemText }}
                    />
                </ListItem>
            </NavLink>

            <NavLink to='/mycalendar'>
                <ListItem
                    button
                    selected={selectedIndex === '/mycalendar'}
                    onClick={(event) => handleListItemClick(event, '/mycalendar')}
                >
                    <ListItemIcon>
                        <EventIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Calendar"
                        classes={{ primary: classes.listItemText }}
                    />
                </ListItem>
            </NavLink>

            <NavLink to='/employeeservices'>
                <ListItem
                    button
                    selected={selectedIndex === '/employeeservices'}
                    onClick={(event) => handleListItemClick(event, '/employeeservices')}
                >
                    <ListItemIcon>
                        <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="My services"
                        classes={{ primary: classes.listItemText }}
                    />
                </ListItem>
            </NavLink>

            <NavLink to='/manageavailability'>
                <ListItem
                    button
                    selected={selectedIndex === '/manageavailability'}
                    onClick={(event) => handleListItemClick(event, '/manageavailability')}
                >
                    <ListItemIcon>
                        <AvTimerIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="My roster"
                        classes={{ primary: classes.listItemText }}
                    />
                </ListItem>
            </NavLink>
        </List>
    );
}

export default ProdNavListEmployee