import {
    GET_IMAGE_LOADING,
    GET_IMAGE,
    GET_IMAGE_ERROR
} from "../actions/StorageActions";

const initialState = {};

const storageReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_IMAGE_LOADING: {
            return {
                ...state
            }
        }
        case GET_IMAGE: {
            return {
                ...state,
                [action.payload.imgPath]: action.payload.imgUrl
            }
        }
        case GET_IMAGE_ERROR: {
            return {
                ...state,
                error: action.err
            };
        }
        default: {
            return state;
        }
    }
};

export default storageReducer;
