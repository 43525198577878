import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ProdNavListOwner from './ProdNavListOwner'
import ProdNavListEmployee from './ProdNavListEmployee'
import { connect } from 'react-redux'
import AvatarMenu from './AvatarMenu'
import { logoutUser } from '../../redux/actions/UserActions'
import logo from '../../images/logo.jpg'
import Box from '@material-ui/core/Box';
import { withRouter } from "react-router-dom";
import { unloadOwnerBusiness } from '../../redux/actions/BusinessActions'
import { unloadEmployeeContract } from '../../redux/actions/ContractActions'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: '#fafafa'
        //background: 'primary',
    },
    appBarSpacer: theme.mixins.toolbar,
    additSpacer: {
        marginTop: theme.spacing(1)
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: 'black'
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        //padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    appBarTitle: {
        flexGrow: 1
    }
}));

// const WhiteTextTypography = withStyles({
//     root: {
//         color: "#000000",
//         flexGrow: 1
//     }
// })(Typography);

const NavBar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <Fragment>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h5"
                            noWrap
                            className={classes.appBarTitle}
                        >
                            <Box
                                fontWeight="fontWeightBold"
                                m={1}
                                letterSpacing={6}
                                className={clsx(open && classes.hide)}
                            >
                                buksa
                            </Box>
                        </Typography>
                    </Fragment>
                        <AvatarMenu
                            userType={props.userType}
                            loadedBusiness={props.loadedBusiness}
                            loadedContract={props.loadedContract}
                            unloadBusiness={props.unloadOwnerBusiness}
                            unloadContract={props.unloadEmployeeContract}
                            logoutUser={props.logoutUser}
                        />
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                {props.userType && props.userType === 'owner' ? (
                    <ProdNavListOwner
                        history={props.history}
                    />
                ) : (
                        <ProdNavListEmployee
                            history={props.history}
                        />
                    )
                }
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.appBarSpacer} />
                <div className={classes.additSpacer} />
                {props.children}
            </main>
        </div>
    );
}

const mapStateToProps = state => {

    const loadedEmployeeContractUid = state.contract.loadedEmployeeContractUid
    const loadedContract = state.firestore.ordered.loadedContract && state.firestore.ordered.loadedContract.find(contract => contract.id == loadedEmployeeContractUid)

    const ownerBusinessList = state.firestore.ordered.ownerBusinessList

    const loadedBusiness = ownerBusinessList && ownerBusinessList.find(business => business.id === state.business.loadedOwnerBusinessUid)

    const userType = state.firebase.profile.userType

    return {
        loadedBusiness,
        loadedContract,
        userType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => {
            dispatch(logoutUser())
        },
        unloadEmployeeContract: () => {
            dispatch(unloadEmployeeContract())
        },
        unloadOwnerBusiness: () => {
            dispatch(unloadOwnerBusiness())
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));