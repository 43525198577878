import SignUp from '../prodSessions/SignUp';

const prodEditProfileRoutes = [
    {
        key: 'edit_profile',
        path: "/editprofile",
        component: SignUp,
        auth: 'nbl_any',
        exact: false
    }
];

export default prodEditProfileRoutes;