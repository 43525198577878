import {
  CREATE_BUSINESS,
  CREATE_BUSINESS_ERROR,
  MODIFY_BUSINESS,
  MODIFY_BUSINESS_ERROR,
  LOAD_OWNER_BUSINESS,
  UNLOAD_OWNER_BUSINESS
} from "../actions/BusinessActions";

const initialState = {};

const businessReducer = function (state = initialState, action) {
  switch (action.type) {
    case CREATE_BUSINESS:
      return {
        ...state,
        loadedOwnerBusinessUid: action.data.resp.id
      }
    case CREATE_BUSINESS_ERROR: {
      return {
        ...state,
        error: action.err
      };
    }
    case MODIFY_BUSINESS: {
      return {
        ...state,
        error: action.err
      };
    }
    case MODIFY_BUSINESS_ERROR: {
      return {
        ...state,
        error: action.err
      };
    }
    case LOAD_OWNER_BUSINESS: {
      return {
        ...state,
        loadedOwnerBusinessUid: action.loadedOwnerBusiness.id
      }
    }
    case UNLOAD_OWNER_BUSINESS: {
      return {
        ...state,
        loadedOwnerBusinessUid: null
      }
    }
    default: {
      return state;
    }
  }
};

export default businessReducer;