export const CREATE_EVENT_BEGIN = "CREATE_EVENT_BEGIN";
export const CREATE_EVENT = "CREATE_EVENT";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR"
export const MODIFY_EVENT_BEGIN = "MODIFY_EVENT_BEGIN";
export const MODIFY_EVENT = "MODIFY_EVENT";
export const MODIFY_EVENT_ERROR = "MODIFY_EVENT_ERROR"
export const DELETE_EVENT_BEGIN = 'DELETE_EVENT_BEGIN'
export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR'

export const createEvent = (event) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        const userType = getState().firebase.profile.userType
        const uid = getState().firebase.auth.uid
        const businessUid = userType === 'owner' ? getState().business.loadedOwnerBusinessUid : getState().contract.loadedEmployeeBusinessUid

        dispatch({
            type: 'CREATE_EVENT_BEGIN',
            data: null
        })

        firestore.collection("event")
            .add({
                bookingOrigin: event.bookingOrigin,
                eventType: event.eventType,
                serviceUid: event.serviceUid,
                contractUid: event.contractUid,
                offerUid: event.offerUid,
                customerUid: event.customerUid,
                eventStartTime: event.eventStartTime,
                eventEndTime: event.eventEndTime,
                billed: event.billed,
                customerName: event.customerName,
                customerPhone: event.customerPhone,
                customerAddress: {
                    formatted_address: event.customerAddress
                },
                customerType: event.customerType,
                duration: event.duration,
                paddingBefore: event.paddingBefore,
                paddingAfter: event.paddingAfter,
                listPrice: event.listPrice,
                discount: event.discount,
                GST: event.GST,
                billPrice: event.billPrice,
                businessUid: businessUid,
                createdByUid: uid,
                createdAt: new Date(),
                lastUpdated: new Date()
            })
            .then(result => {
                dispatch({
                    type: 'CREATE_EVENT',
                    data: result
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'CREATE_EVENT_ERROR',
                    err: err
                })
            })
    }
}

export const modifyEvent = (event) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        const userType = getState().firebase.profile.userType
        const uid = getState().firebase.auth.uid
        const businessUid = userType === 'owner' ? getState().business.loadedOwnerBusinessUid : getState().contract.loadedEmployeeBusinessUid

        dispatch({
            type: 'MODIFY_EVENT_BEGIN',
            data: null
        })

        firestore.runTransaction(t => {
            const docRef = firestore.collection("event").doc(event.eventId)
            return t.get(docRef)
                .then(doc => {
                    t.update(docRef, {
                        bookingOrigin: event.bookingOrigin,
                        eventType: event.eventType,
                        serviceUid: event.serviceUid,
                        contractUid: event.contractUid,
                        offerUid: event.offerUid,
                        customerUid: event.customerUid,
                        eventStartTime: event.eventStartTime,
                        eventEndTime: event.eventEndTime,
                        billed: event.billed,
                        customerName: event.customerName,
                        customerPhone: event.customerPhone,
                        customerAddress: {
                            formatted_address: event.customerAddress
                        },
                        customerType: event.customerType,
                        duration: event.duration,
                        paddingBefore: event.paddingBefore,
                        paddingAfter: event.paddingAfter,
                        listPrice: event.listPrice,
                        discount: event.discount,
                        GST: event.GST,
                        billPrice: event.billPrice,
                        businessUid: businessUid,
                        createdByUid: uid,
                        //createdAt: new Date(),
                        lastUpdated: new Date()
                    })
                })
        })
            .then((result) => {
                dispatch({
                    type: 'MODIFY_EVENT',
                    data: result
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'MODIFY_EVENT_ERROR',
                    err: err
                })
            })
    }
}

export const deleteEvent = (event) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore()

        dispatch({
            type: 'DELETE_EVENT_BEGIN'
        })

        firestore.runTransaction(t => {
            const docRef = firestore.collection("event").doc(event.eventId)
            return t.get(docRef)
                .then(doc => {
                    t.delete(docRef)
                })
                .then((result) => {
                    dispatch({
                        type: 'DELETE_EVENT',
                        data: result
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: 'DELETE_EVENT_ERROR',
                        err: err
                    })
                })
        })
    }
}