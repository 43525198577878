export const CREATE_OFFER_BEGIN = "CREATE_OFFER_BEGIN";
export const CREATE_OFFER = "CREATE_OFFER";
export const CREATE_OFFER_ERROR = "CREATE_OFFER_ERROR"

export const MODIFY_OFFER_BEGIN = "MODIFY_OFFER_BEGIN";
export const MODIFY_OFFER = "MODIFY_OFFER";
export const MODIFY_OFFER_ERROR = "MODIFY_OFFER_ERROR"

export const createOffer = (offer) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        let FieldValue = getFirestore().FieldValue;

        const uid = getState().firebase.auth.uid
        const businessUid = getState().business.loadedOwnerBusinessUid

        dispatch({
            type: 'CREATE_OFFER_BEGIN',
            data: null
        })

        firestore.collection("offer")
            .add({
                offerName: offer.offerName,
                startDate: offer.startDate,
                endDate: offer.endDate,
                eligibleCustomerType: offer.eligibleCustomerType,
                anyService: offer.anyService,
                applicableServices: offer.applicableServices,
                offerType: offer.offerType,
                discountPercentage: offer.discountPercentage,
                discountDollar: offer.discountDollar,
                discountVoucher: offer.discountVoucher,
                businessUid: businessUid,
                createdByUid: uid,
                createdAt: new Date(),
                lastUpdated: new Date()
            })
            .then(resp => {
                dispatch({
                    type: 'CREATE_OFFER',
                    data: resp
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'CREATE_OFFER_ERROR',
                    err: err
                })
            })
    }
}

export const modifyOffer = (offer) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make async call
        //const firestore = firebase.firestore()
        const firestore = getFirestore()
        let FieldValue = getFirestore().FieldValue;

        const uid = getState().firebase.auth.uid
        const businessUid = getState().business.loadedOwnerBusinessUid

        const offerId = offer.offerId

        dispatch({
            type: 'MODIFY_OFFER_BEGIN',
            data: null
        })

        firestore.runTransaction(t => {
            const docRef = firestore.collection("offer").doc(offerId)
            return t.get(docRef)
                .then(doc => {
                    t.update(docRef, {
                        offerName: offer.offerName,
                        startDate: offer.startDate,
                        endDate: offer.endDate,
                        eligibleCustomerType: offer.eligibleCustomerType,
                        anyService: offer.anyService,
                        applicableServices: offer.applicableServices,
                        offerType: offer.offerType,
                        discountPercentage: offer.discountPercentage,
                        discountDollar: offer.discountDollar,
                        discountVoucher: offer.discountVoucher,
                        businessUid: businessUid,
                        createdByUid: uid,
                        //createdAt: new Date(),
                        lastUpdated: new Date()
                    })
                })
        })
            .then((result) => {
                dispatch({
                    type: 'MODIFY_OFFER',
                    data: result
                })
            })
            .catch((err) => {
                dispatch({
                    type: 'MODIFY_OFFER_ERROR',
                    err: err
                })
            })
    }
}